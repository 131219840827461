<template>
  <div style="min-height: 400px" :class="actionType">
    <div v-if="isShopifyEnabled">
      <div class="add-user-header-div">
        <div class="add-user-title d-flex align-center justify-lg-space-between">
          <div class="action-provider-settings-title d-flex flex-column">
            <h4>Shopify</h4>
            <div>Manage actions to add item(s) to cart, discounts and links.</div>
          </div>
        </div>
        <RfButton
          icon="add"
          button-text="Add"
          color="success"
          :disabled="userStore.isDisabledWithMessage"
          @click="addShopifyAction"
        />
      </div>
      <v-simple-table class="rf-settings-table-data website-actions-table">
        <thead>
          <tr>
            <th class="website-actions-table-col1">Name</th>
            <th class="website-actions-table-col2">Code</th>
            <th class="website-actions-table-col2" v-if="userStore.isUserSuperAdmin">
              Super Admin Only
            </th>
            <th v-if="!isTrigger" class="website-actions-table-col3">Prompts</th>
            <th v-if="!isWebsiteAction" class="website-actions-table-col3">Triggers</th>
            <th class="website-actions-table-col3"></th>
          </tr>
        </thead>
        <tbody v-if="actions.length > 0">
          <template v-for="(action, index) in actions">
            <RfClientActionsRow
              v-if="isShopifyAction(action)"
              :key="index"
              :action="action"
              :retentions="prompts"
              :triggers="triggers"
              :defaultCode="defaultCode"
              :isWebsiteAction="isWebsiteAction"
              :isTrigger="isTrigger"
              @edit="editShopifyAction(action, index)"
              @remove="deleteShopifyAction(action)"
            />
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td align="center" colspan="7">
              <div class="settings-no-data-message">Nothing to show</div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-dialog v-model="shopifyActionWindow" persistent max-width="700" width="700">
        <v-card>
          <v-card-title class="headline">Shopify Actions</v-card-title>
          <v-card-text>
            <div class="mb-5">
              Add or edit Shopify actions. Refer to the
              <a target="_blank" href="https://help.redfast.com/docs/shopify">documentation</a> for
              additional information.
            </div>
            <RfTextInput
              v-model="shopifyActionName"
              title="Action name"
              placeholder="e.g: Add t-shirt to cart"
              outlined
              :disabled="userStore.isReadOnly"
              dense
              class="website-action-name"
            />
            <RfSelect
              outlined
              dense
              hide-details
              :items="shopifyActionTypes"
              v-model="shopifyActionType"
              :disabled="userStore.isReadOnly"
              v-if="!shopifyActionIndex"
              title="Type"
              placeholder="Select an action"
            />
            <RfTextInput
              v-if="shopifyActionType === 'url' || shopifyActionUrl"
              v-model="shopifyActionUrl"
              title="URL"
              placeholder="e.g: /cart"
              :disabled="userStore.isReadOnly"
              outlined
              dense
              class="website-action-name mt-7"
            />
            <RfTextInput
              v-if="shopifyActionType === 'discount' || shopifyActionDiscount"
              v-model="shopifyActionDiscount"
              title="Discount code"
              :disabled="userStore.isReadOnly"
              outlined
              dense
              placeholder="e.g: SUMMERSALE"
              class="website-action-name mt-7"
            />

            <div
              v-if="
                shopifyActionType === 'add_to_cart' ||
                (shopifyActionItems && shopifyActionItems.length > 0)
              "
              class="customKeysValuesDiv mt-7"
            >
              <!-- TABLE TO LIST KEY PAIRS -->
              <v-simple-table class="rf-settings-table-data">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Product</th>
                      <th>Quantity</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in shopifyActionItems" :key="index">
                      <td>{{ product.id }}</td>
                      <td>{{ product.qty }}</td>
                      <td align="right">
                        <RfButton
                          icon="clear"
                          color="error"
                          small
                          :disabled="userStore.isDisabledWithMessage"
                          @click="deleteShopifyProduct(index)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- END -->
              <div class="d-flex justify-end">
                <RfButton
                  icon="add"
                  button-text="Add"
                  color="success"
                  small
                  :disabled="userStore.isDisabledWithMessage"
                  @click="openShopifyProductModal"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn
              width="100"
              depressed
              class="cancel-btn"
              outlined
              @click="closeShopifyActionWindow"
            >
              {{ !userStore.isDisabledWithMessage ? "Cancel" : "Close" }}
            </v-btn>
            <RfButton
              button-text="Submit"
              color="primary"
              width="150"
              :disabled="userStore.isDisabledWithMessage || !canSubmitShopifyAction"
              @click="saveShopifyAction"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- ADD PRODUCT AND QUANTITY MODAL FORM -->

      <v-dialog v-model="addShopifyProductModal" max-width="550px">
        <v-card>
          <v-card-title>
            <span>Add item(s) to cart</span>
          </v-card-title>
          <v-card-text>
            <div class="mb-5">
              Use this
              <a
                href="https://help.shopify.com/en/manual/online-store/os/using-themes/change-the-layout/find-variant-id"
                target="_blank"
                >link</a
              >
              to find the variant Id of a product on Shopify.
            </div>
            <v-row>
              <v-col cols="8">
                <RfTextInput
                  outlined
                  dense
                  placeholder="e.g: 37685636923554"
                  title="Product variant ID"
                  v-model="shopifyNested.id"
                />
              </v-col>
              <v-col cols="4">
                <RfTextInput
                  placeholder=" "
                  outlined
                  dense
                  title="Quantity"
                  hide-details
                  type="number"
                  v-model="shopifyNested.qty"
                  width="100px"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn
              width="100"
              class="cancel-btn"
              outlined
              depressed
              @click.stop="closeShopifyProductModal"
              >Cancel</v-btn
            >
            <v-btn
              width="150"
              depressed
              color="primary"
              @click="addShopifyProduct"
              :disabled="!canSubmitShopifyProduct"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END -->
    </div>
    <div>
      <div class="add-user-header-div">
        <div class="add-user-title d-flex align-center justify-lg-space-between">
          <div class="action-provider-settings-title">
            <div>
              Add your {{ itemName }} here. Refer to the
              <a target="_blank" href="https://help.redfast.com/docs/shopify">documentation</a> for
              additional information.
            </div>
          </div>
        </div>
        <RfButton
          icon="add"
          button-text="Add"
          color="success"
          :disabled="userStore.isDisabledWithMessage"
          @click="editAction(-1)"
        />
      </div>
      <v-simple-table class="rf-settings-table-data website-actions-table">
        <thead>
          <tr>
            <th class="website-actions-table-col1">Name</th>
            <th class="website-actions-table-col2">Code</th>
            <th class="w-px" v-if="userStore.isUserSuperAdmin">Super Admin Only</th>
            <th v-if="!isTrigger" class="w-full">Prompts</th>
            <th v-if="!isWebsiteAction" class="w-full">Triggers</th>
            <th class="w-px" />
          </tr>
        </thead>
        <tbody v-if="actions.length">
          <template v-for="(action, index) in actions">
            <RfClientActionsRow
              v-if="canShow(action)"
              :key="index"
              :action="action"
              :defaultCode="defaultCode"
              :retentions="prompts"
              :triggers="triggers"
              :isWebsiteAction="isWebsiteAction"
              :isTrigger="isTrigger"
              @edit="editAction(index)"
              @remove="openDeleteModal(index)"
            />
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td align="center" colspan="3">
              <div class="settings-no-data-message">Nothing to show</div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-dialog v-model="addNewActionDialog" persistent width="700">
        <v-card class="rf-custom-web-card-wrapper">
          <v-card-title>Add new custom action</v-card-title>
          <v-card-text>
            <RfTextInput
              v-model="editingAction.name"
              :title="objectType + ' Name'"
              :placeholder="namePlaceholder"
              outlined
              dense
              :disabled="userStore.isReadOnly"
              class="website-action-name"
            />
            <RfTextInput
              v-model="fieldData"
              title="Field Data"
              placeholder='Enter field data (ex. [{"var":"align",  "label":"Hero Alignment",  "type":"dropdown",  "values":["none","right","left"],  "tooltip":"Align Hero text relative to image"}])'
              outlined
              dense
              :disabled="userStore.isReadOnly"
            />
            <p class="">Write your Javascript code here.</p>
            <PrismEditor
              v-model="editingAction.code"
              language="js"
              :emitEvents="false"
              :readonly="userStore.isReadOnly"
              v-if="addNewActionDialog"
            ></PrismEditor>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn large depressed class="cancel-btn px-7" outlined @click="closeDialog">
              {{ !userStore.isDisabledWithMessage ? "Cancel" : "Close" }}
            </v-btn>
            <RfButton
              :button-text="submitCTA"
              color="success"
              :disabled="userStore.isDisabledWithMessage || !submitEnabled"
              class="px-10"
              large
              @click="submitAction"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteActionDialog" width="640" persistent>
        <v-card class="rf-custom-web-card-wrapper">
          <v-card-title>Are you sure you want to delete?</v-card-title>
          <v-card-text>
            <div v-if="isWebsiteAction">
              The following prompts would be affected:
              <ul class="actionitems-dls">
                <li v-for="item in affectedPromos" :key="item.name">
                  <router-link :to="promoLink(item)">{{ item.name }}</router-link>
                </li>
              </ul>
            </div>
            <div v-else>
              The following triggers would be affected:
              <ul class="actionitems-dls">
                <li v-for="item in affectedTriggers" :key="item.name">{{ item.name }}</li>
              </ul>
              <div class="actionitems-del-warning">
                <v-icon size="16" color="error" left>warning</v-icon
                ><span
                  >You will need to remove this advanced trigger manually from any affected
                  triggers.</span
                >
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn large depressed class="cancel-btn px-7" outlined @click="closeDeleteModal">
              Cancel
            </v-btn>
            <v-btn large class="px-16" depressed color="success" @click="deleteAction(deleteIndex)">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import PrismEditor from "vue-prism-editor";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useActionsStore } from "@/pinia/actionsStore";
import { useTriggersStore } from "@/pinia/triggersStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { computed } from "vue";
import { useUserStore } from "@/pinia/userStore";
import RfClientActionsRow from "./RfClientActionsRow.vue";

export default {
  props: ["app", "actionType"],
  components: { PrismEditor, RfTextInput, RfSelect, RfButton, RfClientActionsRow },
  setup: () => {
    const allPromptsStore = useAllPromptsStore();
    return {
      actionsStore: useActionsStore(),
      triggersStore: useTriggersStore(),
      userStore: useUserStore(),
      allPromptsStore,
      prompts: computed(() => allPromptsStore.prompts),
    };
  },
  data() {
    return {
      actionsKey: 0,
      fieldData: "",
      addNewActionDialog: false,
      shopifyActionWindow: false,
      addShopifyProductModal: false,
      editingAction: {},
      deleteActionDialog: false,
      deleteIndex: -1,
      shopifyNested: {
        id: "",
        qty: "1",
      },
      shopifyActionItems: [],
      shopifyActionName: null,
      shopifyActionIndex: null,
      shopifyActionRegex: /^\/\/ SHOPIFY(.*)/,
      shopifyUrl: this.app.providers.shopify.shop_url,
      shopifyActionType: null,
      shopifyActionTypes: [
        { text: "Redirect to URL", value: "url" },
        { text: "Add discount code", value: "discount" },
        { text: "Add item(s) to cart", value: "add_to_cart" },
      ],
      shopifyActionUrl: null,
      shopifyActionDiscount: null,
    };
  },
  methods: {
    openShopifyProductModal() {
      this.shopifyNested = { id: "", qty: "1" };
      this.addShopifyProductModal = true;
    },
    closeShopifyProductModal() {
      this.addShopifyProductModal = false;
    },

    canShow(action) {
      if (this.isShopifyAction(action)) return false;
      if (action.internal) return this.userStore.isUserSuperAdmin;
      return true;
    },
    isShopifyAction(item) {
      return this.shopifyActionRegex.test(item.code);
    },
    parseShopifyAction(action) {
      const instructions = action.code.match(this.shopifyActionRegex).pop();

      return JSON.parse(instructions);
    },
    editShopifyAction(action, index) {
      this.shopifyActionIndex = index;

      this.shopifyActionUrl = null;
      this.shopifyActionType = null;
      this.shopifyActionItems = null;
      this.shopifyActionDiscount = null;

      this.shopifyActionName = action.name;
      const parsedAction = this.parseShopifyAction(action);

      if (parsedAction.url) {
        const url = new URL(parsedAction.url);

        if (/discount/.test(parsedAction.url)) {
          this.shopifyActionDiscount = url.pathname.replace("/discount/", "");
        } else {
          this.shopifyActionUrl = url.href.replace(this.shopifyUrl, "");
        }
      }

      if (Array.isArray(parsedAction)) this.shopifyActionItems = parsedAction;

      this.openShopifyActionWindow();
    },
    deleteShopifyAction(action) {
      this.actionsStore.deleteClientAction({ appId: this.app.id, actionId: action.id });
    },
    addShopifyProduct() {
      this.shopifyActionItems.push({ ...this.shopifyNested });

      this.addShopifyProductModal = false;
      this.shopifyNested = { id: "", qty: "1" };
    },
    deleteShopifyProduct(index) {
      this.shopifyActionItems.splice(index, 1);
    },
    addShopifyAction() {
      this.shopifyActionIndex = null;
      this.shopifyActionName = null;
      this.shopifyActionItems = [];
      this.shopifyActionType = null;
      this.shopifyActionUrl = null;
      this.shopifyActionDiscount = null;

      this.openShopifyActionWindow();
    },
    shopifyAddToCartActionUrl() {
      let path = "";

      this.shopifyActionItems.forEach(item => {
        path += `updates[${item.id}]=${item.qty}&`;
      });

      const shopUrl = new URL(this.shopifyUrl).href;
      return `${shopUrl}cart/update?${path}`;
    },
    async saveShopifyAction() {
      const action = this.actions[this.shopifyActionIndex] || this.defaultAction();
      action.name = this.shopifyActionName;

      let url;
      let obj;

      if (this.shopifyActionItems && this.shopifyActionItems.length > 0) {
        url = this.shopifyAddToCartActionUrl();
        obj = JSON.stringify(this.shopifyActionItems);
      } else if (this.shopifyActionUrl || this.shopifyActionDiscount) {
        const urlObj = new URL(this.shopifyUrl);

        if (this.shopifyActionDiscount)
          urlObj.pathname = `/discount/${this.shopifyActionDiscount}?redirect=/cart`;
        else urlObj.pathname = this.shopifyActionUrl.replace(this.shopifyUrl, "");

        url = decodeURIComponent(urlObj.href);
        obj = JSON.stringify({ url });
      }

      const newTab = `window.open('${url}', '_blank');`;

      action.code = `// SHOPIFY ${obj}\n${newTab}`;

      if (action.id) {
        await this.actionsStore.updateClientAction({
          appId: this.app.id,
          actionId: action.id,
          model: action,
        });
        this.actionsKey++;
      } else {
        this.actionsStore.createClientAction({ appId: this.app.id, newAction: action });
      }

      this.closeShopifyActionWindow();
    },
    promoLink(item) {
      return { path: `/apps/${this.$route.params.aid}/retentions/${item.id}` };
    },
    editAction(index) {
      if (index >= 0) {
        this.editingAction = cloneDeep(this.actions[index]);
        if (this.editingAction.arg_metadata)
          this.fieldData = JSON.stringify(this.editingAction.arg_metadata);
      } else {
        this.editingAction = this.defaultAction();
      }
      this.editingActionIndex = index;
      this.addNewActionDialog = true;
    },
    openShopifyActionWindow() {
      this.shopifyActionWindow = true;
    },
    closeShopifyActionWindow() {
      this.shopifyActionWindow = false;
    },
    deleteAction(index) {
      const action = this.actions[index];
      this.actionsStore.deleteClientAction({ appId: this.app.id, actionIdIn: action.id });
      this.closeDeleteModal();
    },
    closeDialog() {
      this.addNewActionDialog = false;
      this.editingAction = this.defaultAction();
      this.fieldData = "";
    },
    async submitAction() {
      if (this.fieldData) this.editingAction.arg_metadata = JSON.parse(this.fieldData);

      if (this.editingAction.id) {
        await this.actionsStore.updateClientAction({
          appId: this.app.id,
          actionId: this.editingAction.id,
          model: this.editingAction,
        });
        this.actionsKey++;
      } else {
        this.actionsStore.createClientAction({ appId: this.app.id, newAction: this.editingAction });
      }
      this.closeDialog();
    },
    defaultAction() {
      return cloneDeep({
        app_id: this.app.id,
        code: this.defaultCode,
        name: null,
        action_type: this.actionType,
      });
    },
    openDeleteModal(index) {
      this.deleteIndex = index;
      if (this.shouldShowDeleteWarning) {
        this.deleteActionDialog = true;
      } else {
        this.deleteAction(index);
      }
    },
    closeDeleteModal() {
      this.deleteActionDialog = false;
      this.deleteIndex = -1;
    },
    validateFieldData(fieldData) {
      if (!fieldData) return true;
      try {
        const data = JSON.parse(fieldData);
        return Array.isArray(data);
      } catch (e) {
        return false;
      }
    },
  },
  computed: {
    canSubmitShopifyProduct() {
      return this.shopifyNested.id && this.shopifyNested.qty > 0;
    },
    canSubmitShopifyAction() {
      return (
        this.shopifyActionName &&
        (this.shopifyActionUrl || this.shopifyActionDiscount || this.shopifyActionItems.length > 0)
      );
    },
    shouldShowDeleteWarning() {
      return this.isWebsiteAction ? !!this.affectedPromos.length : !!this.affectedTriggers.length;
    },
    isTrigger() {
      return this.actionType === "custom_trigger";
    },
    isWebsiteAction() {
      return this.actionType === "website_action";
    },
    isShopifyEnabled() {
      return this.app.providers.shopify.active && this.isWebsiteAction;
    },
    itemName() {
      if (this.isWebsiteAction) return "custom actions";
      if (this.isTrigger) return "advanced triggers";
      return "items";
    },
    defaultCode() {
      return this.isTrigger
        ? `/* Write your Javascript function here and add your trigger to a prompts
This function should return a Boolean indicating whether or not the prompts should be shown

Example: Video play ends
var vid = document.querySelector("video");
return vid.duration <= vid.currentTime
*/
`
        : `/* Write your Javascript code here and add your action to a prompts
It will be triggered after a user has accepted an offer

Example: Upgrade a subscription - you must return your api promise or else it may not execute:
return MySubscriptionService.upgrade({ plan: "gold" });

Example: Open a modal:
const modal = document.getElementById("signup-modal");
if (modal) modal.click();
*/
`;
    },
    submitCTA() {
      return this.editingAction?.id ? "Update Action" : "Create Action";
    },
    submitEnabled() {
      return !!(
        this.editingAction.name &&
        this.editingAction.code &&
        (!this.addNewActionDialog || this.validateFieldData(this.fieldData))
      );
    },
    objectType() {
      return this.isTrigger ? "Trigger" : "Action";
    },
    namePlaceholder() {
      return this.isTrigger ? "Video End" : "Upgrade Plan";
    },
    affectedPromos() {
      if (this.deleteIndex >= 0) {
        const deleteId = this.actions[this.deleteIndex].id;
        return this.prompts.filter(retention => {
          const affected = retention.action_group_connector_actions.find(
            agca => agca.connector_action.id === deleteId,
          );
          return !!affected;
        });
      }
      return [];
    },
    affectedTriggers() {
      if (this.deleteIndex >= 0) {
        const deleteId = this.actions[this.deleteIndex].id;
        return this.triggers.filter(trigger => trigger.custom_trigger_id === deleteId);
      }
      return [];
    },
    actions() {
      this.actionsKey;
      return this.allActions.filter(item => item.action_type === this.actionType) || [];
    },
    triggers() {
      return this.triggersStore.triggers;
    },
    allActions() {
      return this.actionsStore.clientActions || [];
    },
  },
  mounted() {
    if (this.isWebsiteAction && !this.prompts.length) this.allPromptsStore.getPrompts(this.app.id);
    if (!this.allActions.length) this.actionsStore.getClientActions(this.app.id);
  },
};
</script>
<style lang="scss">
.actionitems-dls {
  list-style: circle;
  margin: 10px 20px;
  li a {
    font-weight: bold;
  }
}
.actionitems-del-warning {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
</style>

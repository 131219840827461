<template>
  <div class="sgt-builder-usage-opt">
    <!-- All / Any of -->
    <v-select
      v-model="isOrLogic"
      outlined
      dense
      hide-details
      background-color="#ffffff"
      height="32px"
      class="w-20"
      :items="LogicItems"
      :disabled="disabled"
      :menu-props="{ offsetY: true }"
      @change="updateFilter"
    />
    <v-icon>chevron_right</v-icon>
    <!-- Tracker -->
    <v-autocomplete
      v-model="parentModels"
      outlined
      dense
      hide-details
      chips
      multiple
      class="segment-builder--subtypes _subtypes"
      background-color="#ffffff"
      placeholder="Select configuration"
      item-text="name"
      item-value="label"
      :disabled="disabled"
      :items="usageItems"
      :item-disabled="parentModelItemDisabled"
      :menu-props="{ maxHeight: 340, offsetY: true, minWidth: 240, maxWidth: 400 }"
      @change="didChangeParentModels"
    >
      <template #item="{ item, attrs }">
        <div class="d-flex align-center flex-grow-1">
          <v-list-item-action>
            <v-checkbox hide-details class="ml-n2 mt-0 pt-0" v-model="attrs.inputValue" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </div>
      </template>
    </v-autocomplete>
    <!-- Range Type -->
    <div v-if="parentModels.length" class="segment-builder--item next-selection">
      <v-icon>chevron_right</v-icon>
      <v-select
        outlined
        dense
        hide-details
        background-color="#ffffff"
        placeholder="Match Type"
        height="32px"
        class="w-48"
        :items="allRangeTypes"
        :item-disabled="rangeTypeItemDisabled"
        :disabled="disabled"
        :menu-props="{ offsetY: true }"
        :value="rangeType"
        @change="didChangeRangeType"
      />
    </div>
    <!-- Values -->
    <div v-if="!!RangeTypesBase[rangeType]" class="d-flex">
      <v-icon>chevron_right</v-icon>
      <v-text-field
        v-model="value1"
        outlined
        dense
        hide-details
        background-color="#ffffff"
        class="w-20"
        :placeholder="rangeType === 'range' ? 'From' : 'e.g.: 12'"
        :disabled="disabled"
        @input="updateFilter"
      />
    </div>
    <div v-if="rangeType === 'range'" class="d-flex items-center">
      <span class="px-2">-</span>
      <v-text-field
        v-model="value2"
        outlined
        dense
        hide-details
        background-color="#ffffff"
        class="w-20"
        placeholder="To"
        :disabled="disabled"
        @input="updateFilter"
      />
    </div>
    <!-- Time period -->
    <div
      v-if="
        !parentModels.includes('concurrent_logins') &&
        rangeType &&
        !['none', 'trending'].includes(rangeType)
      "
      class="d-flex items-center"
    >
      <v-icon>chevron_right</v-icon>
      <span class="whitespace-nowrap pr-3">Over the last</span>
      <v-select
        v-model="timePeriod"
        outlined
        dense
        hide-details
        background-color="#ffffff"
        height="32px"
        class="w-24"
        placeholder="Days"
        :items="Object.values(RawUsageTimePeriods)"
        :disabled="disabled"
        :menu-props="{ offsetY: true }"
        @change="updateFilter"
      />
    </div>
    <!-- Trending -->
    <div v-if="rangeType === 'trending'" class="d-flex items-start py-1">
      <v-icon>chevron_right</v-icon>
      <div class="ml-1 flex flex-col gap-4">
        <div class="flex">
          <div class="ml-1 mr-3">Range:</div>
          <RfRangeSlider
            v-model="trendRange"
            :min="0"
            :max="10"
            :step="1"
            :disabled="disabled"
            :isNormalized="true"
            @input="updateFilter"
          />
        </div>
        <div class="flex gap-2">
          <v-select
            v-model="trendDirection"
            outlined
            dense
            hide-details
            background-color="#ffffff"
            placeholder="Trend Direction"
            class="w-44"
            height="32px"
            :items="allTrendDirections"
            :disabled="disabled"
            @change="updateFilter"
          />
          <v-select
            v-model="trendDuration"
            outlined
            dense
            hide-details
            background-color="#ffffff"
            placeholder="Time Period"
            class="w-44"
            height="32px"
            :items="allTrendDurations"
            :disabled="disabled"
            @change="updateFilter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getUsageFilterData } from "@/utils/segmentsHelpers";
import { useCustomFields } from "@/utils/composables/useCustomFields";
import { onMounted, ref } from "vue";
import {
  RangeTypes,
  RangeTypesBase,
  RawUsageTimePeriods,
  LogicItems,
} from "@/utils/constants/SegmentsConstants";
import RfRangeSlider from "@/components/RfRangeSlider.vue";

const props = defineProps(["model", "isInOrGroups", "disabled"]);
const emit = defineEmits(["update:model", "updateOrGroups"]);

const customFields = useCustomFields();
const usageItems = getUsageFilterData(customFields.appCustomFields.value);

const allRangeTypes = Object.values(RangeTypes);
const allTrendDirections = [
  { value: "trending_up", text: "Trending Up" },
  { value: "trending_down", text: "Trending Down" },
  { value: "any", text: "Any Direction" },
];
const allTrendDurations = [
  { value: "monthly", text: "Month over month" },
  { value: "weekly", text: "Week over week" },
  { value: "daily", text: "Day over day" },
];

const isOrLogic = ref(false);
const parentModels = ref([]);
const rangeType = ref();
const value1 = ref();
const value2 = ref();
const timePeriod = ref();
const trendRange = ref([0, 10]);
const trendDirection = ref();
const trendDuration = ref();

const resetValues = () => {
  value1.value = undefined;
  value2.value = undefined;
  timePeriod.value = undefined;
  trendRange.value = [0, 10];
  trendDirection.value = undefined;
  trendDuration.value = undefined;
};

const didChangeParentModels = to => {
  if (rangeTypeItemDisabled({ value: rangeType.value }) || !to.length) {
    rangeType.value = undefined;
  }
  if (!to.length) {
    resetValues();
  }
  updateFilter();
};
const didChangeRangeType = toValue => {
  const fromValue = rangeType.value;
  rangeType.value = toValue;
  if (!RangeTypesBase[fromValue] || !RangeTypesBase[toValue]) resetValues();
  updateFilter();
};

const parentModelItemDisabled = ({ label }) => {
  return (
    (parentModels.value.includes("concurrent_logins") && label !== "concurrent_logins") ||
    (parentModels.value.length > 0 &&
      !parentModels.value.includes("concurrent_logins") &&
      label === "concurrent_logins")
  );
};
const rangeTypeItemDisabled = ({ value }) => {
  if (value === "none")
    return parentModels.value.includes("minutes") || parentModels.value.includes("visits");
  if (value === "trending") return parentModels.value.includes("concurrent_logins");
  return false;
};

const valuesForRangeType = () => {
  switch (rangeType.value) {
    case "none":
      return ["0", "0"];
    case "at_least_once":
      return ["1", "INF"];
    case "equal_to":
      return [value1.value, value1.value];
    case "less_than_or_equal_to":
      return ["0", value1.value];
    case "greater_than_or_equal_to":
      return [value1.value, "INF"];
    case "range":
      return [value1.value, value2.value];
    case "trending":
      return trendRange.value.map(String);
  }
};
const updateFilter = () => {
  const filters = {
    usage: parentModels.value.reduce(
      (obj, parentModel) => ({
        ...obj,
        [parentModel]: {
          options: {
            trend: trendDirection.value,
            frequency: trendDuration.value,
            range_type: rangeType.value,
            time_period_days: timePeriod.value,
          },
          values: valuesForRangeType(),
        },
      }),
      {},
    ),
  };
  emit("update:model", filters);
  emit("updateOrGroups", isOrLogic.value, parentModels.value);
};

onMounted(() => {
  const { usage } = props.model;
  parentModels.value = Object.keys(usage);
  if (!parentModels.value.length) return;

  let { options, values } = usage[parentModels.value[0]];
  trendDirection.value = options.trend;
  trendDuration.value = options.frequency;
  rangeType.value = options.range_type;
  timePeriod.value = options.time_period_days;
  [value1.value, value2.value] = values;
  trendRange.value = values;
  isOrLogic.value = props.isInOrGroups;
});
</script>

<style lang="scss" scoped>
:deep(.v-autocomplete) .v-select__selections > .v-chip {
  margin: 2px 4px;

  ~ input {
    min-width: 20px;
    max-width: 20px;
  }
}
</style>

<template>
  <div class="promo-list-view-wrapper">
    <RfPromotionsListViewItem
      v-if="prompt.id"
      :item="prompt"
      :currChartSrc="currChartSrc"
      :openAssignTrafficModal="openAssignTrafficModal"
      :bucketRange="displayBucketRange(segmentItemFor(props.segment, prompt))"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import RfPromotionsListViewItem from "@/components/RfPromotions/RfPromotionsListViewItem.vue";
import ApiPaths from "@/apis/ApiPaths";
import { debounce } from "lodash-es";
import ApiMetrics from "@/apis/ApiMetrics";
import { useRoute } from "vue-router/composables";
import { segmentItemFor, displayBucketRange } from "@/utils/segmentsHelpers";

const props = defineProps({
  promptId: { type: String, required: true },
  currChartSrc: { type: String, default: "last_seven_days" },
  dateRange: { type: Array, default: null },
  segment: { type: Object, default: {} },
  openAssignTrafficModal: { type: Function },
});
const route = useRoute();
const prompt = ref({});

const fetchMetrics = debounce(() => {
  if (!props.currChartSrc && !props.dateRange) return;
  ApiMetrics.getOnePath(route.params.aid, props.promptId, {
    metric_periods: [{ period: props.currChartSrc, date_range: props.dateRange }],
  }).then(v => (prompt.value.data = v));
}, 200);

const fetchPath = () =>
  ApiPaths.getPath(route.params.aid, props.promptId).then(
    v => (prompt.value = { ...v, ...prompt.value }),
  );

onMounted(fetchPath);

watch([() => props.currChartSrc, () => props.dateRange], fetchMetrics, {
  deep: true,
  immediate: true,
});

defineExpose({ prompt });
</script>

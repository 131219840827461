import compositeImageSettings from "./compositeImageSaver.csv";
import domtoimage from "dom-to-image-more";

export default {
  isElementVisible(element) {
    if (!element) {
      return false;
    }

    const style = window.getComputedStyle(element);

    if (
      style.display === "none" ||
      style.visibility === "hidden" ||
      style.visibility === "collapse"
    ) {
      return false;
    }

    if (parseFloat(style.opacity) === 0) {
      return false;
    }

    const hasDimensions = element.offsetWidth > 0 && element.offsetHeight > 0;
    if (!hasDimensions) {
      return false;
    }

    const rect = element.getBoundingClientRect();
    const isPartiallyVisible =
      rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.right > 0;

    return isPartiallyVisible;
  },
  async captureDiv(element, options = {}) {
    const rect = element.getBoundingClientRect();
    const canvas = await domtoimage.toCanvas(element, {
      width: Math.floor(rect.width * options.resize),
      height: Math.floor(rect.height * options.resize),
      useCORS: true,
      style: {
        margin: 0,
        padding: 0,
        position: "static",
        transform: "none",
        transformOrigin: "top left",
        transform: `scale(${options.resize})`,
        transformOrigin: "top left",
      },
      filter: node => {
        if (node.nodeType !== 1 || !options.invisibleElements) {
          return true;
        }
        const selectors = options.invisibleElements.split(",").map(selector => selector.trim());
        for (const selector of selectors) {
          if (selector.startsWith(".")) {
            const className = selector.slice(1);

            if (node.classList && node.classList.contains(className)) {
              return false;
            }
          } else if (selector.startsWith("#")) {
            const idName = selector.slice(1);
            if (node.id === idName) {
              return false;
            }
          }
        }

        return true;
      },
      adjustClonedNode(node, clonedNode, after) {
        if (!after) return;

        function applyComputedStyles(orig, element) {
          if (!element || !(element instanceof Element)) return;
          const computedStyle = window.getComputedStyle(orig);

          Object.values(computedStyle).forEach(property => {
            let computedValue = computedStyle.getPropertyValue(property);
            let currentInlineValue = orig.style.getPropertyValue(property);

            if (currentInlineValue.length && currentInlineValue !== computedValue) {
              element.style.setProperty(property, computedValue);
            }
          });
        }
        applyComputedStyles(node, clonedNode);
      },
    });

    let quality = Math.min(1, Math.max(0, Number(options.imgQuality) / 100));
    const imageData = canvas.toDataURL(options.mimetype, quality);
    if (imageData === "data:,") return;
    return imageData;
  },
  dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  },

  async fill(model, $refs, app) {
    await this[model.device_type](model, $refs, app);
  },

  async addCompositeImages(model, $refs, previewComponents, app) {
    for (const { preview, action, legacy } of previewComponents) {
      if ($refs[preview]) {
        const element = $refs[preview].$el || $refs[preview];
        const setting = compositeImageSettings.find(item => {
          const result = item.action === action && item.path_type.match(model.path_type);
          if (legacy) {
            return result && (item.legacy || "").toString().match(/true/i);
          }
          return result;
        });

        if (!setting) continue;

        const foundElem = element.matches(setting.selector)
          ? element
          : element.querySelector(setting.selector);
        if (!foundElem || !this.isElementVisible(foundElem)) continue;
        const img = await this.captureDiv(foundElem, {
          invisibleElements: setting.invisible_elements,
          resize: setting.resize,
          model,
          mimetype: app.flags.composite_img_mimetype,
          imgQuality: app.flags.composite_img_quality,
        });

        if (!img) continue;
        model.actions[action] = img;
      }
    }
  },

  async web(model, $refs, app) {
    const previewComponents = [
      { action: "rf_settings_bg_image_web_desktop_composite", preview: "web_desktop" },
      { action: "rf_settings_bg_image_web_desktop_preview_composite", preview: "web_desktop" },
      { action: "rf_settings_bg_image_web_mobile_composite", preview: "web_mobile" },
      // widget mobile
      { action: "rf_settings_bg_image_web_mobile_composite", preview: "rfwidgetMobile" },
      // bottom_banner
      { action: "rf_settings_bg_image_web_desktop_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_web_desktop_preview_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_web_mobile_composite", preview: "rfmodalMobile" },
      // tile
      { action: "rf_settings_bg_image_web_desktop_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_web_desktop_preview_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_web_mobile_composite", preview: "mobile_tile" },
      // text
      { action: "rf_settings_bg_image_web_desktop_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_web_desktop_preview_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_web_mobile_composite", preview: "mobile" },
    ];

    await this.addCompositeImages(model, $refs, previewComponents, app);
  },

  async ios(model, $refs, app) {
    const previewComponents = [
      { action: "rf_settings_bg_image_ios_ipad_preview_composite", preview: "ios_ipad" },
      { action: "rf_settings_bg_image_ios_ipad_composite", preview: "ios_ipad" },
      { action: "rf_settings_bg_image_ios_iphone_composite", preview: "ios_ipad" },
      // interstitial 2x
      {
        action: "rf_settings_bg_image_ios_iphone_2x_composite",
        preview: "ios_ipad",
      },
      // bottom_banner
      { action: "rf_settings_bg_image_ios_ipad_preview_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_ios_ipad_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_ios_iphone_composite", preview: "rfmodal" },
      // tile
      { action: "rf_settings_bg_image_ios_ipad_preview_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_ios_ipad_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_ios_iphone_composite", preview: "desktop_tile" },
      // text
      { action: "rf_settings_bg_image_ios_ipad_preview_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_ios_ipad_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_ios_iphone_composite", preview: "desktop" },
    ];

    if (app?.flags?.legacy_apple_editor && model.path_type !== "interstitial") {
      previewComponents[2].preview = "ios_iphone";
      previewComponents[2].legacy = true;
      previewComponents[3].preview = "ios_iphone";
      previewComponents[3].legacy = true;
    }
    await this.addCompositeImages(model, $refs, previewComponents, app);
  },
  async android_os(model, $refs, app) {
    const previewComponents = [
      {
        action: "rf_settings_bg_image_android_os_tv_preview_composite",
        preview: "android_os_tv",
      },
      { action: "rf_settings_bg_image_android_os_tv_composite", preview: "android_os_tv" },
      { action: "rf_settings_bg_image_android_os_tablet_composite", preview: "android_os_phone" },
      { action: "rf_settings_bg_image_android_os_phone_composite", preview: "android_os_phone" },
      // interstitial 2x
      { action: "rf_settings_bg_image_android_os_phone_2x_composite", preview: "android_os_phone" },
      // bottom_banner
      { action: "rf_settings_bg_image_android_os_tv_preview_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_android_os_tv_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_android_os_tablet_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_android_os_phone_composite", preview: "rfwidgetMobile" },
      // tile
      { action: "rf_settings_bg_image_android_os_tv_preview_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_android_os_tv_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_android_os_tablet_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_android_os_phone_composite", preview: "desktop_tile" },
      // text
      { action: "rf_settings_bg_image_android_os_tv_preview_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_android_os_tv_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_android_os_tablet_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_android_os_phone_composite", preview: "desktop" },
    ];

    if (model.path_type === "interstitial") {
      previewComponents[3].preview = "android_os_tv";
      previewComponents[4].preview = "android_os_tv";
    } else if (!app?.flags?.new_android_editor) {
      // use primary tab ref for tablet in old editor
      previewComponents[2].preview = "android_os_tv";
      previewComponents[2].legacy = true;

      // use legacy settings for android phone only
      previewComponents[3].legacy = true;
      previewComponents[4].legacy = true;
    }
    await this.addCompositeImages(model, $refs, previewComponents, app);
  },
  async tv_os(model, $refs, app) {
    const previewComponents = [
      { action: "rf_settings_bg_image_tv_os_tv_preview_composite", preview: "tv" },
      { action: "rf_settings_bg_image_tv_os_tv_composite", preview: "tv" },
      // bottom_banner
      { action: "rf_settings_bg_image_tv_os_tv_preview_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_tv_os_tv_composite", preview: "rfmodal" },
      // tile
      { action: "rf_settings_bg_image_tv_os_tv_preview_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_tv_os_tv_composite", preview: "desktop_tile" },
      // text
      { action: "rf_settings_bg_image_tv_os_tv_preview_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_tv_os_tv_composite", preview: "desktop" },
    ];
    await this.addCompositeImages(model, $refs, previewComponents, app);
  },
  async roku_os(model, $refs, app) {
    const previewComponents = [
      { action: "rf_settings_bg_image_roku_os_tv_preview_composite", preview: "tv" },
      { action: "rf_settings_bg_image_roku_os_tv_composite", preview: "tv" },
      // bottom_banner
      { action: "rf_settings_bg_image_roku_os_tv_preview_composite", preview: "rfmodal" },
      { action: "rf_settings_bg_image_roku_os_tv_composite", preview: "rfmodal" },
      // tile
      { action: "rf_settings_bg_image_roku_os_tv_preview_composite", preview: "desktop_tile" },
      { action: "rf_settings_bg_image_roku_os_tv_composite", preview: "desktop_tile" },
      // text
      { action: "rf_settings_bg_image_roku_os_tv_preview_composite", preview: "desktop" },
      { action: "rf_settings_bg_image_roku_os_tv_composite", preview: "desktop" },
    ];
    await this.addCompositeImages(model, $refs, previewComponents, app);
  },
  async custom_defined(model, $refs, app) {
    const previewComponents = [
      { action: "rf_settings_bg_image_custom_defined_desktop_preview_composite", preview: "tv" },
      { action: "rf_settings_bg_image_custom_defined_desktop_composite", preview: "tv" },
      // bottom_banner
      {
        action: "rf_settings_bg_image_custom_defined_desktop_preview_composite",
        preview: "rfmodal",
      },
      { action: "rf_settings_bg_image_custom_defined_desktop_composite", preview: "rfmodal" },
      // tile
      {
        action: "rf_settings_bg_image_custom_defined_desktop_preview_composite",
        preview: "desktop_tile",
      },
      { action: "rf_settings_bg_image_custom_defined_desktop_composite", preview: "desktop_tile" },
      // text
      {
        action: "rf_settings_bg_image_custom_defined_desktop_preview_composite",
        preview: "desktop",
      },
      { action: "rf_settings_bg_image_custom_defined_desktop_composite", preview: "desktop" },
    ];
    await this.addCompositeImages(model, $refs, previewComponents, app);
  },
};

<template>
  <div class="rf-main-content-wrapper gap-4">
    <div class="flex items-center justify-between">
      <h1 class="flex-shrink-0 text-xl font-medium uppercase not-italic">Performance</h1>
      <RfDatePicker
        ref="datePicker"
        :min="dayjs().subtract(90, 'days')"
        :max="dayjs()"
        :include="['today', 'last_seven_days', 'this_week', 'this_month']"
        @input="v => ((date = v), getDashboardMetrics())"
      />
    </div>
    <RfDashboardSummaryBlock
      :users="totalUsers"
      :loading="loadingData"
      :impressions="totalImpressions"
      :clicks="totalClicks"
      :timePeriod="comparePeriod"
      :ctr="totalCTR"
    />
    <div class="grid grid-cols-1 items-center justify-between gap-4 lg:grid-cols-2">
      <RfDashboardCard title="Top prompts">
        <template #more>
          <RouterLink
            class="text-buttons relative inline-flex items-center gap-1 before:absolute before:-inset-3"
            :to="`/apps/${$route.params.aid}/prompts`"
          >
            See all
            <RfArrowRightIcon class="mb-0.5 !h-6 !w-6 !fill-blue-1" />
          </RouterLink>
        </template>
        <template #body>
          <RfTable
            :tableData="{ totalCount: prompts?.length, loadMore: 5 }"
            :hideHeader="!loadingData && !prompts?.length"
            :class="{ 'h-inherit': !loadingData && !prompts?.length }"
            :loading="loadingData"
            :rowSkeleton="RfDashboardTableRowSkeleton"
          >
            <template #no-data>
              <div class="flex pb-11 pt-4">
                <div class="mx-auto inline-flex flex-col gap-4">
                  <span class="text-body"
                    >Looks like you haven't created any prompts yet. Let's create one</span
                  >
                  <RfButtonNew
                    text="+ New Prompt"
                    data-cy="prompts-table--create-prompt"
                    class="mx-auto"
                    :disabled="userStore.isDisabledWithMessage"
                    @click="showPromoDeviceTypeDialog"
                  />
                  <RfPromotionCreator
                    :pathGroups="allPromptsStore.promptGroups"
                    :promoDeviceTypeDialog="promoDeviceTypeDialog"
                    @closePromoDeviceTypeDialog="closePromoDeviceTypeDialog"
                  />
                </div>
              </div>
            </template>
            <template #thead> <RfDashboardTableHeader /> </template>
            <template #tbody>
              <template v-if="!loadingData">
                <RfDashboardTableRow
                  v-for="prompt in prompts"
                  :key="`prompt-${prompt.id}`"
                  v-bind="prompt"
                  type="prompt"
                  :customDevicesEnabled="currApp.flags?.custom_devices"
                  :link="getPromptLink(prompt.id, currApp.id)"
                />
              </template>
            </template>
          </RfTable>
        </template>
      </RfDashboardCard>
      <RfDashboardCard title="Top guides">
        <template #more>
          <RouterLink
            class="text-buttons relative inline-flex items-center gap-1 before:absolute before:-inset-3"
            :to="`/apps/${$route.params.aid}/experiences`"
          >
            See all
            <RfArrowRightIcon class="mb-0.5 !h-6 !w-6 !fill-blue-1" />
          </RouterLink>
        </template>
        <template #body>
          <RfTable
            :tableData="{ totalCount: guides?.length, loadMore: 5 }"
            :hideHeader="!loadingData && !guides?.length"
            :class="{ 'h-inherit': !loadingData && !prompts?.length }"
            :loading="loadingData"
            :rowSkeleton="RfDashboardTableRowSkeleton"
            :rowSkeletonProps="{ hideImpressions: true }"
          >
            <template #no-data>
              <div class="flex pb-11 pt-4">
                <div class="mx-auto inline-flex flex-col gap-4">
                  <span class="text-body"
                    >Looks like you haven't created any guides yet. Let's create one</span
                  >
                  <RfButtonNew
                    text="+ New Guide"
                    type="main"
                    class="mx-auto"
                    :disabled="userStore.isDisabledWithMessage"
                    @click="guideModal.show()"
                  />
                  <RfGuideModal
                    ref="guideModal"
                    :guide="guideModel"
                    @reset="guideModel.$reset"
                    :submitCallback="submitGuide"
                  />
                </div>
              </div>
            </template>
            <template #thead> <RfDashboardTableHeader hideImpressions convRateOverCtr /> </template>
            <template #tbody>
              <template v-if="!loadingData">
                <RfDashboardTableRow
                  v-for="guide in guides"
                  :key="guide.id"
                  v-bind="guide"
                  type="guide"
                  :link="getGuideLink(guide.id, currApp.id)"
                  hideImpressions
                />
              </template>
            </template>
          </RfTable>
        </template>
      </RfDashboardCard>
      <RfDashboardCard title="Active experiments">
        <template #body>
          <RfTable
            :tableData="{ totalCount: variations?.length, loadMore: 5 }"
            :hideHeader="!loadingData && !variations?.length"
            :class="{ 'h-inherit': !loadingData && !prompts?.length }"
            :loading="loadingData"
            :rowSkeleton="RfDashboardTableRowSkeleton"
          >
            <template #thead> <RfDashboardTableHeader /> </template>
            <template #tbody>
              <template v-if="!loadingData">
                <RfDashboardTableRow
                  v-for="variation in variations"
                  :key="`${variation.id}`"
                  v-bind="variation"
                  type="experiment"
                  :link="getExperimentLink(variation.id, variation.promptId, currApp.id)"
                />
              </template>
            </template>
          </RfTable>
        </template>
      </RfDashboardCard>
      <RfDashboardCard title="Activity log">
        <!-- add modal with full list of activities -->
        <!-- <template #more> <button class="text-buttons">See all</button> </template> -->
        <template #body>
          <div class="flex flex-col gap-3">
            <RfDashboardActivityRow
              v-for="activity in appsStore.activities"
              v-bind="activity"
              :appId="currApp.id"
            />
          </div>
        </template>
      </RfDashboardCard>
    </div>
  </div>
</template>

<script>
import RfDashboardSummaryBlock from "@/blocks/RfDashboard/RfDashboardSummaryBlock.vue";
import RfDashboardCard from "@/components/cards/RfDashboardCard.vue";
import RfArrowRightIcon from "@/components/icons/RfArrowRightIcon.vue";
import RfDatePicker from "@/components/inputs/RfDatePicker.vue";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import { debounce } from "@/utils/debounce";
import { computed, reactive, ref } from "vue";
import MetricsUtils from "@/utils/MetricsUtils";
import { percentage } from "@/utils/metricsHelpers";
import RfDashboardTableHeader from "@/blocks/RfDashboard/RfDashboardTableHeader.vue";
import RfDashboardTableRow from "@/blocks/RfDashboard/RfDashboardTableRow.vue";
import { getPromptLink, getGuideLink, getExperimentLink } from "@/utils/getLink";
import RfDashboardTableRowSkeleton from "@/blocks/RfDashboard/RfDashboardTableRowSkeleton.vue";
import RfDashboardActivityRow from "@/blocks/RfDashboard/RfDashboardActivityRow.vue";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import dayjs from "dayjs";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfPromotionCreator from "@/components/RfPromotions/RfPromotionCreator.vue";
import RfGuideModal from "@/blocks/RfModals/RfGuideModal.vue";
import { useSequencesStore } from "@/pinia/sequencesStore";
import { useAppsStore } from "@/pinia/appsStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  components: {
    RfDashboardSummaryBlock,
    RfDatePicker,
    RfDashboardCard,
    RfArrowRightIcon,
    RfTable,
    RfDashboardTableHeader,
    RfDashboardTableRow,
    RfDashboardActivityRow,
    RfButtonNew,
    RfPromotionCreator,
    RfGuideModal,
  },
  setup: () => {
    const appsStore = useAppsStore();
    const guideModel = reactive({
      id: null,
      name: "",
      description: "",
      sequence_type: "web_client",
      segments: [],
      $reset: () => {
        guideModel.id = null;
        guideModel.name = guideModel.description = "";
        guideModel.sequence_type = "web_client";
        guideModel.segments = [];
      },
    });
    return {
      loadingData: ref(true),
      date: ref({}),
      getPromptLink,
      getGuideLink,
      getExperimentLink,
      dayjs,
      RfDashboardTableRowSkeleton,
      guideModal: ref(),
      guideModel,
      sequencesStore: useSequencesStore(),
      allPromptsStore: useAllPromptsStore(),
      userStore: useUserStore(),
      appsStore,
      currApp: computed(() => appsStore.app),
    };
  },
  mixins: [UserSettingsMixin],
  data() {
    return {
      promoDeviceTypeDialog: false,
      getDashboardMetrics: debounce(async () => {
        this.loadingData = true;
        await this.appsStore.getAppDashboard({
          appId: this.currApp.id,
          elasticMetrics: this.currApp?.flags?.elastic_dashboard_metrics,
          params: {
            metric_periods: [
              {
                period: this.date.preset.value,
                ...(this.date.preset.value === "custom" && {
                  date_range: [
                    this.date.startDate.format("YYYY-MM-DD"),
                    this.date.endDate.format("YYYY-MM-DD"),
                  ],
                }),
              },
              ...(this.comparePeriod ? [{ period: this.comparePeriod }] : []),
            ],
          },
        });
        this.loadingData = false;

        this.date.preset.value !== "custom" &&
          (await this.setUserSetting("dashboardDateRange", this.date.preset.value));
      }, 330),
    };
  },
  computed: {
    prompts() {
      return this.appsStore.promptMetrics[this.date.preset?.value]?.data
        .slice(0, 5)
        .map(
          ({
            id,
            name,
            goals,
            impressions,
            uimpressions,
            thumbnail,
            path_type,
            device_type,
            custom_devices,
            creator,
          }) => ({
            id,
            name,
            users: uimpressions.uniques,
            goals: goals.uniques,
            impressions: impressions.count,
            ctr: percentage(goals.uniques, impressions.count, true),
            thumbnail,
            path_type,
            device_type,
            custom_devices,
            creator,
          }),
        );
    },
    guides() {
      return this.appsStore.sequenceMetrics[this.date.preset?.value]?.data
        .slice(0, 5)
        .map(
          ({
            id,
            name,
            goals,
            thumbnail,
            sequence_type,
            uimpressions,
            paths_count,
            users,
            conversion_rate,
          }) => ({
            id,
            name,
            users: uimpressions?.uniques ?? users?.uniques,
            goals: goals.uniques,
            ctr: `${conversion_rate}%`,
            thumbnail,
            sequence_type,
            paths_count,
          }),
        );
    },
    variations() {
      return this.appsStore.promptsExperimentMetrics[this.date.preset?.value]?.data
        .slice(0, 5)
        .map(({ id, name, goals, impressions, uimpressions, path, creator, thumbnail }) => ({
          id,
          name: `${name} (${path.name})`,
          users: uimpressions.uniques,
          goals: goals.uniques,
          impressions: impressions.count,
          ctr: percentage(goals.uniques, impressions.count, true),
          thumbnail,
          promptId: path.id,
          path_type: path.path_type,
          creator,
        }));
    },
    comparePeriod() {
      return MetricsUtils.Comparisons?.[this.date.preset?.value] || null;
    },
    totalCTR() {
      const curr = percentage(this.totalClicks.curr, this.totalImpressions.curr);
      const last = percentage(this.totalClicks.last, this.totalImpressions.last);
      return { curr, last };
    },
    totalImpressions() {
      if (this.currApp?.flags?.elastic_dashboard_metrics) {
        const curr =
          Object.values(
            this.appsStore.dashboardRetentionMetrics[this.date.preset?.value]?.agg_data || {},
          )[0]?.impression || 0;
        const last =
          Object.values(
            this.appsStore.dashboardRetentionMetrics[this.comparePeriod]?.agg_data || {},
          )[0]?.impression || 0;
        return { curr, last };
      }
      const curr =
        this.appsStore.promptMetrics[this.date.preset?.value]?.count_totals?.impressions || 0;
      const last = this.appsStore.promptMetrics[this.comparePeriod]?.count_totals?.impressions || 0;
      return { curr, last };
    },
    totalClicks() {
      if (this.currApp?.flags?.elastic_dashboard_metrics) {
        const curr =
          Object.values(
            this.appsStore.dashboardRetentionMetrics[this.date.preset?.value]?.agg_data || {},
          )[0]?.goal || 0;
        const last =
          Object.values(
            this.appsStore.dashboardRetentionMetrics[this.comparePeriod]?.agg_data || {},
          )[0]?.goal || 0;
        return { curr, last };
      }
      const curr = this.appsStore.promptMetrics[this.date.preset?.value]?.count_totals?.goals || 0;
      const last = this.appsStore.promptMetrics[this.comparePeriod]?.count_totals?.goals || 0;
      return { curr, last };
    },
    totalUsers() {
      const curr = this.appsStore.segmentMetrics[this.date.preset?.value]?.uniques || 0;
      const last = this.appsStore.segmentMetrics[this.comparePeriod]?.uniques || 0;
      return { curr, last };
    },
  },
  methods: {
    closePromoDeviceTypeDialog() {
      this.promoDeviceTypeDialog = false;
    },
    showPromoDeviceTypeDialog() {
      this.allPromptsStore.getPromptGroups(this.currApp.id);
      this.promoDeviceTypeDialog = true;
    },

    async submitGuide({ name, description, fillModel }) {
      fillModel?.(this.guideModel);
      const { sequence_type, segments } = this.guideModel;
      const guide = { name, description, sequence_type, segments };

      const { id } = await this.sequencesStore.createSequence({
        appId: this.currApp.id,
        newSequence: guide,
      });
      this.$router.replace({ path: getGuideLink(id, this.currApp.id) });

      this.guideModel.$reset();
    },
  },
  mounted() {
    this.$nextTick(() => {
      const preset = MetricsUtils.All_Periods.find(
        ({ value }) =>
          value !== "since_start_date" && value === this.getUserSetting("dashboardDateRange"),
      )?.value;

      this.$refs.datePicker?.setTimeFromPreset(preset || "this_week");
    });
  },
};
</script>

export const getUsageFilterData = segmentCustomFields =>
  !segmentCustomFields?.length
    ? []
    : segmentCustomFields
        .filter(f => f.is_usage)
        .map(f => ({
          ...f,
          name: f.connector_type === "segment" ? `segment.io - ${f.name}` : f.name,
        }));

export const customOrUsageFilters = (data, segmentCustomFilter) =>
  data.reduce((a, { label }) => ({ ...a, [label]: segmentCustomFilter[label] }), {});

export const getCustomFiltersData = segmentCustomFilter =>
  segmentCustomFilter
    .filter(
      field =>
        field.data_type &&
        field.display_type &&
        !field.is_disabled &&
        field.connector_type === "aws_s3" &&
        field.display_type !== "compound",
    )
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

export const isUserFilterDisabled = (fieldName, segmentSystemFields = []) =>
  (segmentSystemFields.find(({ label }) => label === fieldName) || {})?.is_disabled;

// User Bucket helpers

export const segmentItemFor = (segment, promptOrGuide) =>
  promptOrGuide.segments.find(({ id }) => id === segment.id);

export const bucketRange = segmentItem => segmentItem?.options?.user_bucket?.range;

export function displayBucketRange(segmentItem) {
  const range = bucketRange(segmentItem);
  return range?.length ? range.map(i => Math.ceil(i / 100)) : [0, 100];
}

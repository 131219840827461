<template>
  <div class="rf-table flex h-full max-h-full flex-col" data-cy="table">
    <div class="flex flex-shrink-0 flex-grow-0 justify-between gap-5 overflow-x-auto">
      <slot name="pretable"></slot>
    </div>
    <div class="rf-table--wrapper h-inherit overflow-x-auto">
      <table class="h-auto w-full rounded-b rounded-tr bg-white-1">
        <thead v-if="!hideHeader">
          <slot name="thead"></slot>
        </thead>
        <TransitionGroup tag="tbody" name="component-fade">
          <slot name="tbody"></slot>
          <tr v-if="!tableData.totalCount && !loading" key="empty">
            <td colspan="999">
              <slot v-if="$slots['no-data'] || $scopedSlots['no-data']" name="no-data"></slot>
              <div v-else class="flex h-32">
                <span class="text-action-buttons m-auto font-bold"> There is no data. </span>
              </div>
            </td>
          </tr>
          <template v-if="loading && rowSkeleton">
            <component
              v-for="row in tableData.loadMore"
              :key="row"
              data-cy="table--skeleton"
              v-bind="rowSkeletonProps"
              :is="rowSkeleton"
            ></component>
          </template>
        </TransitionGroup>
      </table>
    </div>
    <div
      v-if="tableData && $listeners?.loadMore"
      class="relative mx-auto flex w-full flex-grow-0"
      :class="{
        'mt-8': !hideTotal || (tableData.loadMore > 0 && tableData.showing > 0 && !loading),
      }"
    >
      <span
        v-if="!hideTotal"
        class="rf-table--total text-action-buttons absolute left-0 top-1/2 text-center align-middle"
      >
        {{ `Showing ${+!!tableData.totalCount}-${tableData.showing} of ${tableData.totalCount}` }}
      </span>
      <RfButtonNew
        v-if="tableData.loadMore > 0 && tableData.showing > 0 && !loading"
        ref="loadMoreButton"
        class="mx-auto !bg-blue-1"
        :text="`Load ${tableData.loadMore} more ${type}`"
        @click="$emit('loadMore')"
      />
    </div>
  </div>
</template>

<script setup>
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import { ref, onBeforeUnmount } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

const emit = defineEmits(["loadMore"]);

const loadMoreButton = ref();
const { stop } = useIntersectionObserver(
  loadMoreButton,
  ([{ isIntersecting }]) => isIntersecting && emit("loadMore"),
);

onBeforeUnmount(stop);

defineProps({
  tableData: { type: Object, default: () => null },
  type: { type: String, default: "items" },
  loading: { type: Boolean, default: false },
  hideHeader: { type: Boolean, default: false },
  hideTotal: { type: Boolean, default: false },
  rowSkeleton: { type: Object, default: null },
  rowSkeletonProps: { type: Object, default: () => ({}) },
});
</script>

<style lang="scss" scoped>
::v-deep.rf-table {
  th {
    @apply text-body px-4 py-2 text-black-2;
  }

  td {
    @apply p-4 align-middle;
    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.rf-table--total {
  transform: translateY(-50%);
}
</style>

<template>
  <div class="mweb-widget-container">
    <div class="mweb-phone-kit-bg" :class="{ 'mweb-phone-kit-bg--none': noFrame }">
      <div class="mweb-canvas-bound">
        <div
          class="mweb-widget-wrapper"
          :class="widgetPositionClassName"
          :style="mobileWidgetWrapperStyle"
          ref="rfdim"
        >
          <div class="mobilewebModalContentWrapper" :style="cssVars">
            <span class="rfmodal-close inline-flex flex-row-reverse">
              <v-icon
                v-if="closeButtonEnabled"
                size="18"
                class="my-auto"
                :style="{ color: fillColor }"
                >close</v-icon
              >
              <RfPromptTimeout :actions="actions" class="my-auto mr-2" newVariant />
            </span>
            <div class="mweb-widget-msg-container">
              <div class="mweb-widget-content-colm" :style="widgetMessageWidth">
                <div>
                  <!-- two extra div layers to match brig -->
                  <div>
                    <div class="rfmodal-header-mobileweb">
                      <h3 :style="mobileWebTitleFontSize" v-html="actions.rf_mobile_title"></h3>
                    </div>
                    <div class="rfmodal-body-mobileweb">
                      <RfBodyCountdown :actions="actions" />
                      <div
                        :style="mobileWebBodyFontSize"
                        v-html="mobileMessage"
                        class="rfmodal-message-mobileweb"
                      ></div>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- extra div layer to match brig -->
                  <div class="rfmodal-footer-mobileweb">
                    <RfPromoInputsPreview :actions="actions" />
                    <RfRetentionMessageButton v-if="!hideButtons" :actions="actions" mobileWeb />
                  </div>
                </div>
              </div>
              <div class="mweb-widget-spacer" :style="columnspacerWidth"></div>
            </div>
          </div>
          <div :style="containerMobileWebBackgroundImage" class="rfmodal-backgroundimage"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import RfPromoInputsPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoInputsPreview.vue";
import RfRetentionMessageButton from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessageButton.vue";
import { getPosition } from "@/utils/prompts/promptHelpers";
import { baseUrlReplacer } from "@/utils/BaseUrlReplacer";
import { useAppsStore } from "@/pinia/appsStore";
import RfBodyCountdown from "./RfBodyCountdown.vue";
import RfPromptTimeout from "./components/RfPromptTimeout.vue";

export default {
  name: "RfWidgetMobileContent",
  props: {
    actions: Object,
    model: Object,
    hideButtons: { type: Boolean, default: false },
    noFrame: { type: Boolean, default: false },
  },
  mixins: [PromoPreviewMixin],
  components: { RfRetentionMessageButton, RfPromoInputsPreview, RfBodyCountdown, RfPromptTimeout },
  setup: () => ({ appsStore: useAppsStore() }),
  computed: {
    cssVars() {
      let bgColor;
      if (this.actions.button1_bg_color) {
        bgColor = this.actions.button1_bg_color;
      }
      return {
        "--btn-color": bgColor,
        "--txt-color": this.fillColor,
      };
    },
    widgetMessageWidth() {
      return {
        "width": this.actions.rf_mobile_message_width,
        "text-align": this.actions.rf_retention_align,
      };
    },
    columnspacerWidth() {
      return {
        width: `calc(100% - ${this.actions.rf_mobile_message_width})`,
        height: "4px",
      };
    },
    widgetPositionClassName() {
      return `bgImg-${this.actions.rf_retention_img_align}`;
    },
    mobileWidgetWrapperStyle() {
      const pos = getPosition(this.actions, true);
      return {
        "top": "unset",
        "bottom": "unset",
        "background-color": this.actions.rf_settings_bg_image_color,
        "width": this.actions.rf_mobile_width,
        "height": this.actions.rf_mobile_height,
        "animation-name": `animate${pos.y}`,
        [pos.y]: this.actions.rf_mobile_banner_position_offset_y,
      };
    },
    mobileWebTitleFontSize() {
      return {
        "font-size": this.actions.rf_settings_mobile_title_font_size,
      };
    },
    mobileWebBodyFontSize() {
      return {
        "font-size": this.actions.rf_settings_mobile_message_font_size,
      };
    },
    containerMobileWebBackgroundImage() {
      if (this.actions.rf_settings_mobile_bg_image) {
        return {
          "background-image": `url(${baseUrlReplacer(this.appsStore.app, this.actions.rf_settings_mobile_bg_image)})`,
        };
      }
      if (this.actions.rf_settings_bg_image) {
        return {
          "background-image": `url(${baseUrlReplacer(this.appsStore.app, this.actions.rf_settings_bg_image)})`,
        };
      }
      return {
        "background-image": "none",
      };
    },
  },
};
</script>

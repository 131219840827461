<template>
  <div class="promo-interaction-opt-wrap">
    <div class="interaction-label">User</div>
    <v-select
      outlined
      dense
      hide-details
      class="w-24 min-w-24"
      placeholder="Condition"
      :disabled="disabled"
      v-model="matchTypeModel"
      :items="matchTypeItems"
      @change="updateFilter"
      :menu-props="{ maxHeight: 310, offsetY: true }"
      height="32px"
      background-color="#ffffff"
    />
    <v-icon>chevron_right</v-icon>
    <v-select
      v-if="hasMatchType"
      v-model="interactionTypeModel"
      chips
      multiple
      outlined
      dense
      hide-details
      :disabled="disabled"
      :items="interactionTypeItems"
      @change="updateFilter"
      placeholder="Event"
      :menu-props="{ maxHeight: 310, offsetY: true, minWidth: 240 }"
      class="pr-interaction-checklist"
      background-color="#ffffff"
    />
    <v-icon>chevron_right</v-icon>
    <v-autocomplete
      v-if="interactionTypeModel"
      v-model="pathSlugs"
      outlined
      dense
      hide-details
      chips
      multiple
      class="segment-builder--subtypes _subtypes"
      background-color="#ffffff"
      placeholder="Select configuration"
      item-text="name"
      item-value="id"
      :disabled="disabled"
      :items="sortedAllPrompts"
      :menu-props="{ maxHeight: 310, offsetY: true, minWidth: 240, maxWidth: 520 }"
      @change="updateFilter"
    >
      <template #item="{ item, attrs }">
        <div class="d-flex align-center flex-grow-1">
          <v-list-item-action>
            <v-checkbox hide-details class="ml-n2 mt-0 pt-0" v-model="attrs.inputValue" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { InteractionTypeItems, MatchTypeItemsAlter } from "@/utils/constants/SegmentsConstants";

export default {
  name: "RfPromoInteractions",
  props: ["filter", "disabled", "isInOrGroups"],
  setup: () => {
    const allPromptsStore = useAllPromptsStore();
    return {
      allPromptsStore,
      matchTypeItems: Object.values(MatchTypeItemsAlter),
      interactionTypeItems: Object.values(InteractionTypeItems),
    };
  },
  data() {
    return {
      matchTypeModel: null,
      interactionTypeModel: null,
      pathSlugs: [],
    };
  },
  computed: {
    allPrompts() {
      return this.allPromptsStore.prompts;
    },
    sortedAllPrompts() {
      return [...this.allPrompts].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0,
      );
    },
    hasMatchType() {
      return this.matchTypeModel !== null;
    },
  },
  methods: {
    updateFilter() {
      const promos = this.pathSlugs.reduce(
        (obj, promo_slug) => ({
          ...obj,
          [promo_slug]: {
            promo_slug,
            positive_match: this.matchTypeModel,
            interaction_types: this.interactionTypeModel,
          },
        }),
        {},
      );
      this.$emit("update:model", { promos });
    },
    prepareFilter() {
      const { promos } = this.filter;
      this.pathSlugs = Object.keys(promos);
      const firstValue = Object.values(promos)[0];
      this.matchTypeModel = firstValue?.positive_match;
      this.interactionTypeModel = firstValue?.interaction_types;
    },
    loadPromos() {
      if (this.allPrompts.length) return;
      this.allPromptsStore.getPrompts(this.$route.params.aid);
    },
  },
  mounted() {
    this.loadPromos();
    this.prepareFilter();
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-autocomplete) .v-select__selections > .v-chip {
  margin: 2px 4px;

  ~ input {
    min-width: 20px;
    max-width: 20px;
  }
}
</style>

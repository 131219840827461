<template>
  <div class="inline-flex overflow-hidden rounded">
    <div
      v-for="(part, i) in rangeParts"
      class="flex items-center justify-center whitespace-nowrap"
      :class="i === 1 ? activeClass : inactiveClass"
      :style="{ width: `${part}%` }"
    >
      <span v-if="part">{{ part }}%</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps(["range", "activeClass", "inactiveClass"]);

const rangeParts = computed(() => [
  props.range[0],
  props.range[1] - props.range[0],
  100 - props.range[1],
]);
</script>

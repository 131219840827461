<template>
  <v-dialog v-model="localAddPromoModule" max-width="700">
    <v-card>
      <v-card-title>{{ submitCTA }} Item: {{ path.name }} </v-card-title>
      <v-card-text>
        <div style="margin-bottom: 16px" class="sequence-continue-conditions" v-if="canContinue">
          <RfSequenceAddPathConditions
            title="continue"
            :editInteractions="editContinueInteractions"
            :disallowedInteractions="[...skipInteractions, ...editInteractions]"
            v-on:setInteractions="setEditContinueInteractions"
            v-on:setInterval="setContinueInterval"
            :isLastPath="editIndex === sequence.paths.length - 1"
            :path="path"
            :interval="continueInterval"
            :key="path.id"
            :webClient="sequence.sequence_type === 'web_client'"
          />
        </div>
        <div style="margin-bottom: 16px" class="sequence-skip-conditions" v-if="canSkip">
          <RfSequenceAddPathConditions
            title="skips"
            :editInteractions="skipInteractions"
            :disallowedInteractions="[...editInteractions, ...continueInteractionsBySequenceType]"
            v-on:setInteractions="setSkipInteractions"
            :path="path"
          />
        </div>
        <div class="sequence-exit-conditions">
          <RfSequenceAddPathConditions
            title="exits"
            :editInteractions="editInteractions"
            :disallowedInteractions="[...skipInteractions, ...continueInteractionsBySequenceType]"
            v-on:setInteractions="setEditInteractions"
            :path="path"
          />
        </div>
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn class="cancel-btn" outlined width="100px" large depressed @click="closeDialog"
          >Cancel</v-btn
        >
        <v-btn
          large
          depressed
          color="success"
          @click="addNewPath"
          :disabled="!submitEnabled"
          width="200"
        >
          {{ submitCTA }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RfSequenceAddPathConditions from "@/components/RfSequences/RfSequenceAddPathConditions.vue";
import { cloneDeep, isEmpty } from "lodash-es";

export default {
  name: "RfSequenceAddPath",
  props: [
    "sequence",
    "addPromoModule",
    "newPathSequenceId",
    "newPathInteractions",
    "isEditing",
    "newPathContinueInteractions",
    "newPathContinueInterval",
    "newPathSkipInteractions",
    "editIndex",
  ],
  components: { RfSequenceAddPathConditions },
  data() {
    return {
      paths: cloneDeep(this.sequence.paths),
      editInteractions: this.newPathInteractions,
      editPathSequenceId: this.newPathSequenceId,
      editContinueInteractions: this.newPathContinueInteractions,
      continueInterval: this.newPathContinueInterval,
      skipInteractions: this.newPathSkipInteractions,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("hideAddPromoModule");
    },
    addNewPath() {
      this.localAddPromoModule = false;
      const { path } = this;
      if (path) {
        const newPath = {
          path_name: path.name,
          id: path.id,
          interaction_types: this.editInteractions,
          continue_interaction_types: this.editContinueInteractions,
          continue_interval_minutes: this.continueInterval,
          skip_interaction_types: this.skipInteractions,
        };
        this.$emit("addPath", newPath);
      }
    },
    setEditInteractions(to) {
      this.editInteractions = to;
    },
    setSkipInteractions(to) {
      this.skipInteractions = to;
    },
    setEditContinueInteractions(to) {
      this.editContinueInteractions = to;
    },
    setContinueInterval(to) {
      this.continueInterval = to;
    },
  },
  computed: {
    localAddPromoModule: {
      get() {
        return this.addPromoModule;
      },
      set(value) {
        this.$emit("update:addPromoModule", value);
      },
    },
    loop() {
      return !!this.sequence?.filter?.loop;
    },
    canContinue() {
      if (this.isOrdered && this.editIndex < this.sequence.paths.length - 1) return true;
      if (this.loop) return true;
    },
    canSkip() {
      return (this.loop && !isEmpty(this.path.custom_filter));
    },
    continueInteractionsBySequenceType() {
      if (this.isOrdered) {
        return this.editContinueInteractions;
      }
      return [];
    },
    path() {
      if (this.editPathSequenceId) {
        return (
          this.sequence.paths.find(retention => this.editPathSequenceId === retention.id) || {}
        );
      }
      return {};
    },
    submitCTA() {
      return this.isEditing ? "Update" : "Add";
    },
    submitEnabled() {
      let enabled = !!this.editPathSequenceId;
      if (this.canContinue) {
        enabled = enabled && (this.editContinueInteractions.length || this.skipInteractions.length);
      }
      return enabled;
    },
    isOrdered() {
      return (
        this.sequence.sequence_type === "ordered" || this.sequence.sequence_type === "web_client"
      );
    },
  },
};
</script>

import ApiSequences from "@/apis/ApiSequences";
import { defineStore } from "pinia";
import { ref } from "vue";
import { catchable } from "./piniaUtils";
import { useGuidesStore } from "@/pinia/guidesStore";

export const useSequencesStore = defineStore("sequences", () => {
  const sequences = ref([]);
  const currSequence = ref(null);
  const guidesStore = useGuidesStore();

  const mutateCurrentSequence = sequence => {
    currSequence.value = sequence;
    sequences.value = sequences.value.map(s => (s.id === sequence.id ? sequence : s));
  };

  const getSequence = ({ appId, sequenceId, params }) =>
    catchable({
      t: async () =>
        mutateCurrentSequence(await ApiSequences.getSequence(appId, sequenceId, params)),
      loadable: true,
    });

  const getSequences = appId =>
    catchable({
      t: async () => (sequences.value = await ApiSequences.getSequences(appId)),
      loadable: true,
    });

  const createSequence = ({ appId, newSequence }) =>
    catchable({
      t: async () => (currSequence.value = await ApiSequences.createSequence(appId, newSequence)),
      loadable: true,
      throwable: true,
    });

  const updateSequence = ({ appId, sequenceId, modelSequence }) =>
    catchable({
      t: async () => {
        const sequence = await ApiSequences.updateSequence(appId, sequenceId, modelSequence);
        const paths = sequence.paths.map(el =>
          currSequence.value?.paths.find(({ id }) => id === el.id),
        );
        mutateCurrentSequence({ ...sequence, ...(paths && { paths }) });
      },
      loadable: true,
      throwable: true,
    });

  const deleteSequence = ({ appId, sequenceId }) =>
    catchable({
      t: async () => {
        await ApiSequences.deleteSequence(appId, sequenceId);
        sequences.value = sequences.value.filter(sequence => sequence.id !== sequenceId);
      },
      loadable: true,
      throwable: true,
    });

  const cloneSequence = ({ appId, sequenceId, targetAppId, defaultCompany }) =>
    catchable({
      t: async () => {
        let sequence;
        if (targetAppId && appId !== targetAppId) {
          sequence = await ApiSequences.cloneSequenceToApp(appId, sequenceId, targetAppId, defaultCompany);
        } else {
          sequence = await ApiSequences.cloneSequence(appId, sequenceId);
          sequences.value = [...sequences.value, sequence];
          getSequence({ appId, sequenceId: sequence.id });
        }

        return sequence;
      },
      loadable: true,
      throwable: true,
    });

  return {
    sequences,
    currSequence,
    getSequence,
    getSequences,
    createSequence,
    updateSequence,
    deleteSequence,
    cloneSequence,
  };
});

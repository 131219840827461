<template>
  <RfBaseModal
    ref="modalRef"
    :title="`Assign Segment Traffic: ${record?.name}`"
    width="640"
    secondary-text="Assign Traffic"
    @secondary="submitAssignTraffic"
  >
    <template #body>
      <div class="flex flex-col gap-6 px-1" v-if="record">
        <div>
          You can limit this {{ recordType }} to be active for only a portion of user traffic in
          this segment. This can be useful to experiment between prompt types. For example, if you
          set the slider to 0-50, and another prompt to 50-100, then half of users in this segment
          will see this {{ recordType }}, and the remainder will see the other prompt.
        </div>
        <div v-if="record.segments.length > 1" class="-mt-2 mb-2 rounded bg-goal px-5 py-4">
          <v-icon class="mr-1 !align-baseline" size="14" color="warning">
            fas fa-exclamation-triangle
          </v-icon>
          This {{ recordType }} is in multiple segments, and will be shown to users of ANY of the
          segments. We recommend experimenting between prompts in a single segment only, or ensuring
          the segments are mutually exclusive.
        </div>
        <p class="font-medium text-black-2">
          <span class="capitalize">{{ recordType }}</span> will be active for
          {{ range[1] - range[0] }}% of segment traffic:
        </p>
        <v-range-slider
          v-model="range"
          strict
          min="0"
          max="100"
          thumb-label="always"
          class="mt-4"
        />
        <RfSegmentBucketRangeBar
          class="pointer-events-none z-10 -mt-20 mb-4 h-10 font-bold"
          activeClass="text-white-1"
          inactiveClass="text-grey-3"
          :range="range"
        />
      </div>
    </template>
  </RfBaseModal>
</template>

<script setup>
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfSegmentBucketRangeBar from "@/components/RfSegments/RfSegmentBucketRangeBar.vue";
import { segmentItemFor, displayBucketRange } from "@/utils/segmentsHelpers";
import { computed, ref } from "vue";
import { useRoute } from "vue-router/composables";
import { usePromptStore } from "@/pinia/promptStore";
import { useSequencesStore } from "@/pinia/sequencesStore";
import { useToastsStore } from "@/pinia/toastsStore";

const route = useRoute();
const promptStore = usePromptStore();
const sequencesStore = useSequencesStore();
const toastsStore = useToastsStore();

const props = defineProps(["segment"]);
const emit = defineEmits(["assignTraffic"]);

const modalRef = ref();
const record = ref();
const range = ref([]);

const recordType = computed(() => (record.value.path_type ? "prompt" : "guide"));
const segmentItem = computed(() => segmentItemFor(props.segment, record.value));

const show = promptOrGuide => {
  record.value = promptOrGuide;
  range.value = displayBucketRange(segmentItem.value);
  modalRef.value.show();
};
const close = () => modalRef.value.close();

const submitAssignTraffic = async () => {
  const appId = route.params.aid;
  segmentItem.value.options.user_bucket.range = [range.value[0] * 100, range.value[1] * 100 - 1];
  if (recordType.value === "prompt") {
    await promptStore.updatePrompt({
      appId,
      pathId: record.value.id,
      modelPath: record.value,
    });
  } else {
    await sequencesStore.updateSequence({
      appId,
      sequenceId: record.value.id,
      modelSequence: record.value,
    });
  }
  toastsStore.create({ type: "success", body: `Traffic assigned for: ${record.value.name}` });
  close();
};

defineExpose({ show, close });
</script>

<style scoped lang="scss">
:deep(.v-slider) {
  &.v-slider--horizontal {
    @apply mx-0 #{!important};
  }
  .v-slider__track-container {
    @apply h-10 overflow-hidden rounded;

    .v-slider__track-background {
      @apply bg-background #{!important};
    }
    .v-slider__track-fill {
      @apply bg-blue-1 #{!important};
    }
  }
  .v-slider__thumb.primary {
    @apply flex h-5 w-4 cursor-pointer items-center justify-center gap-1 rounded bg-white-1  shadow-sm  #{!important};
    &:before {
      @apply bg-transparent static h-3 w-2 transform-none rounded-none border-x-2 border-solid border-strokes opacity-100 #{!important};
    }
  }
  .v-slider__thumb-label-container {
    @apply -mt-3;
  }
  .v-slider__thumb-label {
    @apply bg-black-1 bg-opacity-80 font-bold #{!important};
  }
}
</style>

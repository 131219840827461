<template>
  <RfChartsExtended :data="metricsFormatted" :skeleton="skeleton" alterNoData :hasData="hasData" />
</template>

<script setup>
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import { chartColors } from "@/utils/constants/ColorsConstants";
import { interactionDenominators } from "@/utils/constants/PromoInteractionConstants";
import { getTotals, percentage, newChartHasData } from "@/utils/metricsHelpers";
import {
  isAccept2Enabled,
  isCustomGoalExists,
  isEmail,
  isInvisible,
  isPushNotification,
} from "@/utils/prompts/promptHelpers";
import { computed } from "vue";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import RfChartsExtended from "../RfCharts/RfChartsExtended.vue";

const props = defineProps({
  skeleton: { type: Boolean, default: false },
  prompt: { type: Object, default: () => ({}) },
  metrics: { type: Object, default: () => ({}) },
  preset: { type: String, default: "last_seven_days" },
  displayMetrics: { type: Object, default: () => ({}) },
});

const isPushNotificationComputed = computed(() => isPushNotification(props.prompt));
const isInvisibleComputed = computed(() => isInvisible(props.prompt));
const isEmailComputed = computed(() => isEmail(props.prompt));

const getTotalByKey = key =>
  props.metrics?.[key]?.[props.preset]?.uniques || props.metrics?.[key]?.[props.preset]?.total || 0;
const getDataByDay = key =>
  (props.metrics?.[key]?.[props.preset]?.data || []).map(el => [el.date, el.count]);
const uniqueEndpointsGroupedByDate = computed(() =>
  getTotalByKey("unique-endpoints-grouped-by-date"),
);
const attemptedDeliveriesGroupedByDate = computed(() =>
  getTotalByKey("attempted-deliveries-grouped-by-date"),
);
const uniqueDeliveriesGroupedByDate = computed(() =>
  getTotalByKey("unique-deliveries-grouped-by-date"),
);
const totalGoalsUniques = computed(() => getTotalByKey("goals"));
const totalUimpressions = computed(() => getTotalByKey("uimpressions"));
const totalImpressions = computed(() => getTotals(props.metrics, "impressions", props.preset));
const totalGoals = computed(() => getTotals(props.metrics, "goals", props.preset));
const totalGoals2 = computed(() => getTotals(props.metrics, "goals2", props.preset));
const totalCtr = computed(() => percentage(totalGoals.value, totalImpressions.value, "new"));
const totalCtr2 = computed(() => percentage(totalGoals2.value, totalImpressions.value, "new"));
const hasCustomGoal = computed(() => isCustomGoalExists(props.prompt));
const accept2Enabled = computed(() => isAccept2Enabled(props.prompt));
const totalConversionRate = computed(() =>
  percentage(totalGoals.value, totalUimpressions.value, "new"),
);
const totalConversionRate2 = computed(() =>
  percentage(totalGoals2.value, totalUimpressions.value, "new"),
);

const totalCustomGoals = computed(() => {
  if (isInvisibleComputed.value) return getTotalByKey("custom_goals_accepted");

  return getTotals(props.metrics, "custom_goals_seen", props.preset);
});
const totalCustomGoalConversionRate = computed(() =>
  percentage(
    totalCustomGoals.value,
    isInvisibleComputed.value ? totalGoalsUniques.value : totalUimpressions.value,
    "new",
  ),
);

const constructPercentage = (numerator = [], denominator = []) => {
  const res = [];
  for (let i = 0; i < numerator?.length; i++) {
    res.push({
      key: numerator[i][0],
      value: { label: percentage(numerator[i][1], denominator[i][1]), raw: 0 },
    });
  }
  return res;
};

const metricsFormatted = computed(() => {
  if (Object.keys(props.metrics).length === 0) return [];
  const colors = [...chartColors];
  const chartData = [];
  if (isPushNotificationComputed.value) {
    chartData.push({
      label: "Endpoints",
      data: Object.fromEntries(getDataByDay("unique-endpoints-grouped-by-date")),
      backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
      order: 1,
      totals: { value: toLocaleNumberString(uniqueEndpointsGroupedByDate.value) },
    });
    chartData.push({
      label: "Attempted Deliveries",
      data: Object.fromEntries(getDataByDay("attempted-deliveries-grouped-by-date")),
      backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
      order: 1,
      totals: { value: toLocaleNumberString(attemptedDeliveriesGroupedByDate.value) },
    });
    chartData.push({
      label: "Deliveries",
      data: Object.fromEntries(getDataByDay("unique-deliveries-grouped-by-date")),
      backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
      order: 1,
      totals: { value: toLocaleNumberString(uniqueDeliveriesGroupedByDate.value) },
    });
  } else {
    chartData.push({
      label: "Users",
      data: Object.fromEntries(
        getDataByDay(isInvisibleComputed.value ? interactionDenominators.accepted : "uimpressions"),
      ),
      backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
      order: 1,
      totals: {
        label: "Total users",
        value: toLocaleNumberString(
          isInvisibleComputed.value ? totalGoalsUniques.value : totalUimpressions.value,
        ),
      },
    });

    if (!isInvisibleComputed.value) {
      chartData.push({
        label: isEmailComputed.value ? "Sends" : "Impressions",
        data: Object.fromEntries(getDataByDay("impressions")),
        backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
        order: 1,
        totals: {
          label: `Total ${isEmailComputed.value ? "sends" : "impressions"}`,
          value: toLocaleNumberString(totalImpressions.value),
        },
      });

      const color = colors.splice(~~(Math.random() * colors.length - 1), 1)[0];
      chartData.push({
        label: "CTR",
        data: constructPercentage(getDataByDay("goals"), getDataByDay("impressions")),
        backgroundColor: color,
        borderColor: color,
        type: "line",
        order: 0,
        parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
        props: { type: "percentage" },
        totals: { text: totalCtr.value.label, value: totalCtr.value.value },
      });

      chartData.push({
        label: props.displayMetrics.goals,
        data: Object.fromEntries(getDataByDay(interactionDenominators.accepted)),
        backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
        order: 1,
        totals: { value: toLocaleNumberString(totalGoals.value) },
      });
      if (!hasCustomGoal.value) {
        const colorConv = colors.splice(~~(Math.random() * colors.length - 1), 1)[0];
        chartData.push({
          label: "Conv. rate",
          data: constructPercentage(getDataByDay("goals"), getDataByDay("uimpressions")),
          backgroundColor: colorConv,
          type: "line",
          order: 0,
          parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
          props: { type: "percentage" },
          totals: {
            text: isInvisibleComputed.value ? "-" : totalConversionRate.value.label,
            value: isInvisibleComputed.value ? 0 : totalConversionRate.value.value,
          },
        });

        if (accept2Enabled.value) {
          chartData.push({
            label: props.displayMetrics.goals2,
            data: Object.fromEntries(getDataByDay(interactionDenominators.accepted2)),
            backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
            order: 1,
            totals: { value: toLocaleNumberString(totalGoals2.value) },
          });

          const colorAdd = colors.splice(~~(Math.random() * colors.length - 1), 1)[0];
          chartData.push({
            label: "CTR (additional)",
            data: constructPercentage(totalGoals2.value, getDataByDay("impressions")),
            backgroundColor: colorAdd,
            borderColor: colorAdd,
            type: "line",
            order: 0,
            parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
            props: { type: "percentage" },
            totals: { text: totalCtr2.value.label, value: totalCtr2.value.value },
          });
          chartData.push({
            label: "Conv. rate (additional)",
            data: constructPercentage(getDataByDay("goals2"), getDataByDay("uimpressions")),
            backgroundColor: colorAdd,
            type: "line",
            order: 0,
            parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
            props: { type: "percentage" },
            totals: {
              text: isInvisibleComputed.value ? "-" : totalConversionRate2.value.label,
              value: isInvisibleComputed.value ? 0 : totalConversionRate2.value.value,
            },
          });
        }
      }
    }
    if (hasCustomGoal.value) {
      const customGoalsByDate = getDataByDay(
        isInvisibleComputed.value ? "custom_goals_accepted" : "custom_goals_seen",
      );
      const customGoalsDenominator = getDataByDay(
        isInvisibleComputed.value ? "goals" : "uimpressions",
      );

      chartData.push({
        label: "Custom goal",
        data: Object.fromEntries(customGoalsByDate),
        backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
        order: 1,
        totals: { value: toLocaleNumberString(totalCustomGoals.value) },
      });
      const customGoalConversionRate = () => {
        const res = [];
        for (let i = 0; i < customGoalsByDate?.length; i++) {
          res.push({
            key: customGoalsByDate[i][0],
            value: {
              label: percentage(customGoalsByDate[i][1], customGoalsDenominator[i][1]),
              raw: 0,
            },
          });
        }
        return res;
      };
      const color = colors.splice(~~(Math.random() * colors.length - 1), 1)[0];
      chartData.push({
        label: "Conv. rate",
        data: customGoalConversionRate(),
        backgroundColor: color,
        type: "line",
        order: 0,
        parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
        props: { type: "percentage" },
        totals: {
          text: totalCustomGoalConversionRate.value.label,
          value: totalCustomGoalConversionRate.value.value,
        },
      });
    }
  }
  return chartData;
});

const hasData = computed(() => newChartHasData(metricsFormatted.value));

defineExpose({ hasData });
</script>

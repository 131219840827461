<template>
  <RfSequencesListItem
    v-if="sequence.id"
    class="rf-sequence-list-item"
    :sequence="sequence"
    :currChartSrc="currChartSrc"
    :openAssignTrafficModal="openAssignTrafficModal"
    :bucketRange="bucketRange"
  />
</template>

<script>
import { debounce } from "lodash-es";
import ApiSequences from "@/apis/ApiSequences";
import RfSequencesListItem from "@/views/sequences/RfSequencesListItem.vue";
import { segmentItemFor, displayBucketRange } from "@/utils/segmentsHelpers";

export default {
  name: "RfSegmentSequence",
  props: ["sequenceId", "currChartSrc", "dateRange", "segment", "openAssignTrafficModal"],
  components: { RfSequencesListItem },
  data(props) {
    return {
      sequence: {},
      fetchSequence: debounce(function () {
        if (!props.currChartSrc && !props.dateRange) return;
        ApiSequences.getSequence(this.$route.params.aid, this.sequenceId, {
          metric_periods: [{ period: this.currChartSrc, date_range: this.dateRange }],
        }).then(v => (this.sequence = v));
      }, 200),
    };
  },
  computed: {
    bucketRange() {
      return displayBucketRange(segmentItemFor(this.segment, this.sequence));
    },
  },
  mounted() {
    this.fetchSequence();
  },
  watch: {
    currChartSrc() {
      this.fetchSequence();
    },
    dateRange: {
      handler() {
        this.fetchSequence();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.rf-sequence-list-item {
  border-radius: 4px;
}
</style>

<template>
  <div class="mweb-widget-container">
    <div
      class="mweb-phone-kit-bg relative"
      :class="
        model.device_type === 'ios'
          ? 'mweb-phone-kit-bg--clear-shrinked'
          : 'mweb-phone-kit-bg--clear'
      "
    >
      <div
        :style="{
          'border-top-left-radius': model.device_type === 'ios' ? '13% 6%' : '0%',
          'border-top-right-radius': model.device_type === 'ios' ? '13% 6%' : '0%',
          'border-bottom-left-radius': '5% 4%',
          'border-bottom-right-radius': '5% 4%',
        }"
      >
        <div class="mweb-canvas-bound mweb-canvas-bound--interstitial mix-blend-multiply">
          <span
            class="rfmodal-close inline-flex flex-row-reverse"
            :style="{ top: model.device_type === 'ios' ? '55px' : undefined }"
          >
            <v-icon
              v-if="actions.rf_settings_close_button_enabled === 'true'"
              id="rfmodal-close-icon"
              class="my-auto"
              :style="{ color: actions.rf_settings_fill_color }"
            >
              close
            </v-icon>
            <RfPromptTimeout :actions="actions" class="my-auto mr-2" newVariant />
          </span>
          <div class="h-full w-full overflow-hidden">
            <div class="h-full w-full" :style="containerMobileWebBackgroundImage">
              <div class="flex h-7/10">
                <div
                  :style="{
                    'text-align': actions.rf_retention_align,
                    'padding': `0 ${actions.rf_settings_body_padding_side}`,
                  }"
                >
                  <div :style="titleStyle">{{ actions.rf_retention_title }}</div>
                  <div :style="messageStyle">{{ actions.rf_retention_message }}</div>
                </div>
              </div>
              <div class="flex h-3/10 flex-col">
                <div
                  ref="buttonContainer"
                  class="mweb-btn-container flex flex-shrink-0 flex-grow flex-col"
                  :key="actions.rf_settings_privacy_policy_text"
                >
                  <button
                    v-if="!isNewDeviceEditor || actions.rf_retention_button1_text"
                    v-html="actions.rf_retention_button1_text"
                    class="mx-8 text-lg"
                    :style="{
                      height: `${buttonHeight}px`,
                      marginTop: `${buttonPadding}px`,
                      ...acceptButtonStyle,
                      ...buttonsStyle,
                    }"
                  ></button>
                  <button
                    v-if="
                      isNewDeviceEditor
                        ? actions.rf_settings_confirm_button_2_enabled &&
                          actions.rf_retention_button2_text
                        : actions.rf_settings_confirm_button_2_enabled
                    "
                    v-html="actions.rf_retention_button2_text"
                    class="mx-8 text-lg"
                    :style="{
                      height: `${buttonHeight}px`,
                      marginTop: `${buttonPadding}px`,
                      ...secondaryButtonStyle,
                      ...buttonsStyle,
                    }"
                  ></button>
                  <button
                    v-if="
                      isNewDeviceEditor
                        ? actions.rf_settings_cancel_button_enabled &&
                          actions.rf_retention_button3_text
                        : actions.rf_settings_cancel_button_enabled
                    "
                    v-html="actions.rf_retention_button3_text"
                    class="mx-8 text-lg"
                    :style="{
                      height: `${buttonHeight}px`,
                      marginTop: `${buttonPadding}px`,
                      ...cancelButtonStyle,
                      ...buttonsStyle,
                    }"
                  ></button>
                </div>
                <RfRetentionLinksPreview
                  v-if="actions.rf_settings_privacy_policy_text"
                  class="!relative !bottom-0 !left-0 mx-8 mb-2 line-clamp-1 text-center"
                  :actions="actions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import { computed, ref } from "vue";
import RfRetentionLinksPreview from "./components/RfRetentionLinksPreview.vue";
import RfPromptTimeout from "./components/RfPromptTimeout.vue";

export default {
  props: {
    model: Object,
    actions: Object,
    isNewDeviceEditor: { type: Boolean, default: false },
  },
  components: { RfRetentionLinksPreview, RfPromptTimeout },
  mixins: [PromoPreviewMixin],
  setup: props => {
    const buttonContainer = ref(null);

    const numberOfButtons = computed(() => {
      let n = 0;
      if (!props.isNewDeviceEditor || props.actions.rf_retention_button1_text) n++;
      if (
        props.isNewDeviceEditor
          ? props.actions.rf_settings_confirm_button_2_enabled &&
            props.actions.rf_retention_button2_text
          : props.actions.rf_settings_confirm_button_2_enabled
      )
        n++;
      if (
        props.isNewDeviceEditor
          ? props.actions.rf_settings_cancel_button_enabled &&
            props.actions.rf_retention_button3_text
          : props.actions.rf_settings_cancel_button_enabled
      )
        n++;
      return n;
    });

    const buttonHeight = computed(
      () => buttonContainer.value?.clientHeight / (numberOfButtons.value + 1),
    );

    const buttonPadding = computed(
      () =>
        (buttonContainer.value?.clientHeight - numberOfButtons.value * buttonHeight.value) /
        (numberOfButtons.value + 1),
    );

    const containerMobileWebBackgroundImage = computed(() => {
      const bgColor = { "background-color": props.actions.rf_settings_bg_image_color };

      if (props.actions.rf_settings_bg_image) {
        return {
          ...bgColor,
          "background-size": props.actions.rf_settings_tile_is_responsive || "cover",
          "background-image": `url(${props.actions.rf_settings_bg_image})`,
        };
      }
      return {
        ...bgColor,
        "background-image": "none",
      };
    });

    return { buttonContainer, buttonHeight, buttonPadding, containerMobileWebBackgroundImage };
  },
  computed: {
    titleStyle() {
      return {
        "font-size": this.actions.rf_settings_title_font_size,
        "color": this.actions.rf_settings_fill_color,
        "margin-top": this.actions.rf_settings_title_padding_top,
      };
    },
    messageStyle() {
      return {
        "font-size": this.actions.rf_settings_message_font_size,
        "color": this.actions.rf_settings_fill_color,
      };
    },
    buttonsStyle() {
      return {
        "border-radius": this.actions.rf_retention_button_border_radius,
        "border-color": this.actions.rf_retention_button_border_color,
        "border-width": this.actions.rf_retention_button_border_thickness,
        "border-style": "solid",
      };
    },
  },
};
</script>

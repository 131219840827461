<template>
  <div class="flex flex-col gap-4 p-6 pb-8 2xl:p-9">
    <RfPromptHeader
      :disabled="userStore.isDisabledWithMessage"
      :lockDatepickerPreset="
        dataCard?.selectedTab?.key === 'activity' ? METRICS_PERIODS.last_seven_days.value : false
      "
      :statusDisabled="!!prompt.sequence_id || !!prompt.pipeline_stage_id"
      :showClone="!userStore.isDisabledWithMessage"
      :showReset="!!prompt.last_published_date"
      :showPriority="!isPlacement"
      :name="prompt.name"
      :description="prompt.description"
      :createdBy="prompt.creator"
      :defaultDate="defaultDate"
      :isEnabled="prompt.is_enabled"
      :id="prompt.id"
      :status="promptStatus"
      :date.sync="date"
      :type="type"
      :skeleton="skeleton"
      :startDate="prompt.start_date"
      :oneYearMetrics="currApp.flags?.one_year_metrics"
      :customDevicesEnabled="customDevicesEnabled"
      @copyId="copyToClipboard(prompt.id, 'Prompt id copied to the clipboard')"
      @editCustomDevices="() => detailModals.showCustomDevicesModal()"
      @back="goBack"
      @edit="editItem = prompt"
      @clone="cloneItem = prompt"
      @priority="() => priorityModal.show?.()"
      @reset="() => resetModal.show?.()"
      @export="exportSinglePromptToCsv({ ...prompt, data: metrics }, date.preset?.value)"
      @delete="deleteItem = [prompt]"
      @update:status="v => (statusUpdate = { prompt: prompt, status: v })"
      @update:date="fetchMetrics"
    />
    <div class="flex flex-3 flex-col gap-4">
      <RfDashboardCard
        ref="dataCard"
        :title="dashboardCardData.title"
        :style="{
          height:
            !biggerOrEqual5xl && dataCard?.selectedTab?.key === 'activity' ? '900px' : '488px',
        }"
        @change="e => e.key === 'activity' && (liveFirstRender = true)"
      >
        <template #[dashboardCardData.template]>
          <RfPromptData
            ref="chart"
            :displayMetrics="displayMetrics"
            :prompt="prompt"
            :skeleton="dataSkeleton"
            :metrics="metrics"
            :preset="date?.preset?.value"
          />
        </template>

        <template #activity>
          <RfLive
            v-if="displayLiveFeed && liveFirstRender"
            class="rf-prompt--live h-full w-full min-w-px [&_.rf-input--input-wrapper]:!bg-input-background"
            :dateFilter="date"
            :promptFilter="prompt"
            style="--rf-prompt-data--height: 345px"
          />
        </template>
      </RfDashboardCard>

      <RfPromptAdditionalData
        :mainChartHasData="chart.hasData"
        :skeleton="dataSkeleton"
        :showInteraction="!isInvisible && !isPushNotification"
        :showCustomGoalInteraction="!isInvisible && !isPushNotification && hasCustomGoal"
        :showSurvey="isSurvey"
        :metrics="metrics"
        :displayMetrics="displayMetrics"
        :displayMetricsCustom="displayMetricsCustom"
        :surveyMetrics="surveyMetrics"
        :surveyInputs="surveyInputs"
        :preset="date?.preset?.value"
      />
    </div>
    <RfPromptDetails
      id="details"
      :prompt="prompt"
      :isPushNotification="isPushNotification"
      :segments="prompt?.segments"
      :appId="currApp.id"
      :sequence="sequence"
      :triggers="prompt?.triggers"
      :promptTags="prompt?.tags"
      :appTags="tagsStore.tags"
      :isDisabledRole="userStore.isDisabledWithMessage"
      :isDisabledByActiveExperiment="isDisabledByActiveExperiment"
      :creativeLink="creativeLink"
      :isInvisible="isInvisible"
      :skeleton="skeleton"
      @editSegments="() => detailModals.showSegmentsModal()"
      @editTriggers="() => detailModals.showTriggersModal()"
      @editLimits="() => detailModals.showLimitsModal()"
      @editSchedule="() => detailModals.showScheduleModal()"
      @editInterval="() => detailModals.showIntervalModal()"
      @createTag="createTagAndAssignToPrompt"
      @removeTag="removeTagFromPrompt"
      @assignTag="assignTagToPrompt"
    />
    <template v-if="!isPushNotification">
      <RfPromptCustomGoal
        :skeleton="skeleton"
        :disabled="userStore.isDisabledWithMessage || isDisabledByActiveExperiment"
        :duration="prompt.actions?.rf_settings_custom_goal_expire_hours"
        :customFilter="prompt.custom_filter"
        :data="totalCustomGoals"
        @add="() => customGoalModal.show()"
        @delete="resetCustomGoal"
      />
      <RfPromptActions
        :skeleton="skeleton"
        :disabled="userStore.isDisabledWithMessage || isDisabledByActiveExperiment"
        :prompt="prompt"
        :currLanguage="promptStore.currLanguage"
        :currAppConnectors="appsStore.currAppConnectors"
        :providers="currApp.providers"
        :appClientActions="appClientActions"
        :actionGroupConnectorActionsGroupedByType="actionGroupConnectorActionsGroupedByType"
        @add="() => connectorModal.show?.()"
        @updateActions="updateActionsOrder"
        @editClientAction="v => connectorModal.editArgs?.(v)"
        @deleteClientAction="v => connectorModal.deleteAction?.(v)"
        @editIntegration="v => connectorModal.editIntegration?.(v)"
        @deleteIntegration="v => connectorModal.deleteAction?.(v)"
      />
      <RfPromptExperiments
        :skeleton="skeleton"
        :disableCreate="
          userStore.isDisabledWithMessage ||
          isDisabledByActiveExperiment ||
          isDisabledByDraftExperiment
        "
        :disableStatusChange="userStore.isDisabledWithMessage || isLocalized"
        :experiments="experimentsStore.experiments"
        :appId="currApp.id"
        :promptId="prompt.id"
        :isInvisible="isInvisible"
        :hasCustomGoal="hasCustomGoal"
        :customGoalInteraction="experimentsStore.customGoalInteraction"
        @add="() => newExperimentModal.show()"
        @status="updateExperiment"
      />
    </template>
    <RfPromptHistory
      :skeleton="skeleton"
      :activities="promptStore.activities || []"
      :connectorErrors="prompt.connector_errors"
      @open="e => connectorErrorModal.show?.(e)"
    />
    <RfRetentionPriority
      v-if="!isPlacement"
      ref="priorityModal"
      :retentions="filteredRetentions"
      :currRetention="prompt"
    />
    <RfBaseModal
      ref="resetModal"
      title="Are you sure you want to reset?"
      message="Reset will result in all currently targeted users becoming eligible to receive this prompt, regardless of previous interactions. All limits and frequency caps will be reset."
      width="580"
      secondary-text="Reset"
      @secondary="submitReset"
    />
    <RfPromptModals
      ref="modals"
      :editItem.sync="editItem"
      :deleteItem.sync="deleteItem"
      :cloneItem.sync="cloneItem"
      :statusUpdate.sync="statusUpdate"
      :currApp="currApp"
      :currUser="userStore.currUser"
      :isSuperAdmin="userStore.isUserSuperAdmin"
      :isCompanyAdmin="userStore.isUserAdmin"
      :timezone="currApp.timezone_offset"
      :onSubmitRename="
        v =>
          promptStore.updatePrompt({
            appId: currApp.id,
            pathId: editItem.id,
            modelPath: { ...editItem, ...v },
          })
      "
      @getPathGroups="allPromptStore.getPromptGroups"
      @delete="submitDelete"
      @clone="submitClone"
      @status="submitStatusUpdate"
    />
    <RfPromptDetailModals
      ref="detailModals"
      :isDisabledRole="userStore.isDisabledWithMessage"
      :isDisabledByActiveExperiment="isDisabledByActiveExperiment"
      :isPushNotification="isPushNotification"
      :prompt="prompt"
      @updatePrompt="updatePrompt"
      @updateTriggers="updateTriggers"
    />
    <RfBaseModal
      ref="customGoalModal"
      title="Add a custom conversion goal"
      width="500"
      @primary="updateCustomGoal"
    >
      <template #body>
        <RfGoal :model="prompt" ref="goal" />
      </template>
    </RfBaseModal>
    <RfBaseModal
      ref="newExperimentModal"
      title="Create a new experiment"
      width="640"
      @primary="saveExperiment"
    >
      <template #body>
        <v-text-field
          outlined
          dense
          label="Name"
          v-model.trim="experimentName"
          name="experimentName"
          required
          hide-details
          class="mb-4 mt-3"
          v-validate="'required'"
          placeholder="Experiment's name"
        ></v-text-field>
      </template>
    </RfBaseModal>
    <RfBaseModal
      v-if="experiemntToUpdate"
      ref="statusExperimentModal"
      :title="`${experiemntToUpdate.is_active ? 'End' : 'Start'} experiment confirmation`"
      width="700"
      :primaryText="`${experiemntToUpdate.is_active ? 'End' : 'Start'} experiment`"
      @primary="submitExperiment"
      @close="experiemntToUpdate = null"
    >
      <template #body>
        <div v-if="experiemntToUpdate.is_active">
          Are you sure you want to stop this experiment?
        </div>
        <div v-else-if="!prompt.is_enabled">
          Your prompt will be published. Are you sure you want to start this experiment?
        </div>
        <div v-else>Are you sure you want to start this experiment?</div>
      </template>
    </RfBaseModal>
    <RfPromptActionsModals
      ref="connectorModal"
      :app="currApp"
      :prompt="prompt"
      :currLanguage="promptStore.currLanguage"
      :currAppConnectors="appsStore.currAppConnectors"
      :appClientActions="appClientActions"
      :clientActionObjects="clientActions"
      :clientActions="actionGroupConnectorActionsGroupedByType.ClientAction"
      @updateClientActions="updateClientActions"
      @deleteAction="deleteAction"
    />
    <RfConnectorErrorModal ref="connectorErrorModal" :prompt="prompt" />
  </div>
</template>

<script>
import RfPromptModals from "@/blocks/RfModals/RfPromptModals.vue";
import RfPromptHeader from "@/blocks/RfPrompt/RfPromptHeader.vue";
import { usePromptsStore } from "@/pinia/promptsStore";
import { useToastsStore } from "@/pinia/toastsStore";
import copyToClipboard from "@/utils/CopyToClipboard";
import { exportSinglePromptToCsv } from "@/utils/CsvUtils";
import { getPromptDeviceType } from "@/utils/getDeviceName";
import {
  getGuideLink,
  getPromptsLink,
  getPipelineLink,
  getZoneLink,
  getPromptLink,
  getGuidePromptLink,
  getExperimentLink,
} from "@/utils/getLink";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import {
  isCustomGoalExists,
  isInvisible,
  isLatestExperimentActive,
  isLatestExperimentUnstarted,
  isLocalized,
  isOverlay,
  isPlacement,
  isPushNotification,
  isSurvey,
} from "@/utils/prompts/promptHelpers";
import { computed, onMounted, ref } from "vue";
import RfPromptDetails from "@/blocks/RfPrompt/RfPromptDetails/RfPromptDetails.vue";
import RfPromptDetailModals from "@/blocks/RfModals/RfPromptDetailModals.vue";
import RfLive from "@/blocks/RfLive/RfLive.vue";
import RfPromptCustomGoal from "@/blocks/RfPrompt/RfPromptCustomGoal.vue";
import RfPromptHistory from "@/blocks/RfPrompt/RfPromptHistory.vue";
import RfPromptActions from "@/blocks/RfPrompt/RfPromptActions/RfPromptActions.vue";
import RfPromptActionsModals from "@/blocks/RfPrompt/RfPromptActions/RfPromptActionsModals.vue";
import RfPromptExperiments from "@/blocks/RfPrompt/RfPromptExperiments/RfPromptExperiments.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfGoal from "@/components/RfPathCreate/RfGoal.vue";
import { cloneDeep } from "lodash-es";
import {
  getSurveyInputs,
  getTotals,
  getDisplayCustomMetrics,
  getDisplayMetrics,
} from "@/utils/metricsHelpers";
import { PATH_CUSTOM_METRIC_SURVEY } from "@/utils/constants/CustomMetricsConstants";
import { groupBy } from "@/utils/arrayUtils";
import { isRedirectAction } from "@/utils/prompts/promptActionHelpers";
import RfPromptData from "@/blocks/RfPrompt/RfPromptData.vue";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import MetricsUtils from "@/utils/MetricsUtils";
import RfConnectorErrorModal from "@/blocks/RfModals/RfConnectorErrorModal.vue";
import { useTagsStore } from "@/pinia/tagsStore";
import ApiTags from "@/apis/ApiTags";
import RfPromptAdditionalData from "@/blocks/RfPrompt/RfPromptAdditionalData.vue";
import { PromptTypes } from "@/utils/constants/PromoTypesConstants";
import RfDashboardCard from "@/components/cards/RfDashboardCard.vue";
import { biggerOrEqual5xl } from "@/utils/composables/useBreakpoint";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";
import { useExperimentsStore } from "@/pinia/experimentsStore";
import { useActionsStore } from "@/pinia/actionsStore";
import { useTriggersStore } from "@/pinia/triggersStore";
import { usePromptStore } from "@/pinia/promptStore";
import { useAppsStore } from "@/pinia/appsStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { useUserStore } from "@/pinia/userStore";
import RfRetentionPriority from "../RfRetentionPriority.vue";
import { METRICS_PERIODS } from "@/utils/constants/MetricsConstants";

export default {
  name: "RfPromptView",
  components: {
    RfPromptHeader,
    RfPromptModals,
    RfRetentionPriority,
    RfBaseModal,
    RfPromptDetails,
    RfPromptDetailModals,
    RfLive,
    RfPromptCustomGoal,
    RfGoal,
    RfPromptHistory,
    RfPromptExperiments,
    RfPromptActionsModals,
    RfPromptActions,
    RfPromptData,
    RfConnectorErrorModal,
    RfPromptAdditionalData,
    RfDashboardCard,
  },
  mixins: [UserSettingsMixin],
  setup: () => {
    const chart = ref({ hasData: false });
    const resetModal = ref();
    const priorityModal = ref();
    const date = ref();
    const editItem = ref(null);
    const deleteItem = ref(null);
    const cloneItem = ref(null);
    const statusUpdate = ref({ status: null, prompt: null });
    const modals = ref();
    const detailModals = ref();
    const customGoalModal = ref();
    const newExperimentModal = ref();
    const statusExperimentModal = ref();
    const goal = ref();
    const experimentName = ref("");
    const experiemntToUpdate = ref(null);
    const skeleton = ref(true);
    const dataSkeleton = ref(true);
    const connectorModal = ref();
    const connectorErrorModal = ref();
    const defaultDate = ref("last_seven_days");
    const tagsStore = useTagsStore();
    const appsStore = useAppsStore();
    const currApp = computed(() => appsStore.app);

    onMounted(() => {
      if (!tagsStore.tags?.length) tagsStore.getTags(currApp.value?.id);
    });

    return {
      chart,
      date,
      editItem,
      deleteItem,
      cloneItem,
      statusUpdate,
      modals,
      copyToClipboard,
      getPromptDeviceType,
      getPromptStatus,
      exportSinglePromptToCsv,
      priorityModal,
      resetModal,
      detailModals,
      customGoalModal,
      goal,
      currApp,
      newExperimentModal,
      statusExperimentModal,
      experimentName,
      experiemntToUpdate,
      connectorModal,
      defaultDate,
      connectorErrorModal,
      skeleton,
      dataSkeleton,
      tagsStore,
      biggerOrEqual5xl,
      appsStore,
      liveFirstRender: ref(false),
      dataCard: ref(),
      toastsStore: useToastsStore(),
      promptsStore: usePromptsStore(),
      toastsStore: useToastsStore(),
      promptsStore: usePromptsStore(),
      userStore: useUserStore(),
      experimentsStore: useExperimentsStore(),
      actionsStore: useActionsStore(),
      triggersStore: useTriggersStore(),
      promptStore: usePromptStore(),
      allPromptStore: useAllPromptsStore(),
      legacyTmpStore: useLegacyTmpStore(),
      METRICS_PERIODS,
    };
  },
  computed: {
    dashboardCardData() {
      return {
        title: this.displayLiveFeed
          ? [
              { key: "performance", value: "Performance" },
              { key: "activity", value: "Live View" },
            ]
          : "Performance",
        template: this.displayLiveFeed ? "performance" : "body",
      };
    },
    clientActions() {
      return this.actionsStore.clientActions || [];
    },
    prompt() {
      return this.promptStore.prompt || {};
    },
    surveyMetrics() {
      return (
        this.promptStore.customMetrics?.[PATH_CUSTOM_METRIC_SURVEY]?.[this.date?.preset?.value]
          ?.agg_data?.[2] || {}
      );
    },
    surveyInputs() {
      return getSurveyInputs(this.prompt);
    },
    metrics() {
      return this.promptStore.promptMetricsId === this.$route.params.pid
        ? this.promptStore.promptMetrics
        : {};
    },
    creativeLink() {
      return `/apps/${this.currApp.id}/retentions/${this.prompt.id}/creative`;
    },
    actionGroupConnectorActionsGroupedByType() {
      return groupBy(this.prompt.action_group_connector_actions || [], "connectable_type");
    },
    appClientActions() {
      return this.clientActions
        .filter(action => action.action_type === "website_action")
        .map(action => ({ text: action.name, value: action.id }));
    },
    isPushNotification() {
      return isPushNotification(this.prompt);
    },
    isPlacement() {
      return isPlacement(this.prompt.path_group?.path_type);
    },
    promptStatus() {
      return getPromptStatus(this.prompt, this.currApp.timezone_offset);
    },
    type() {
      return getPromptDeviceType(this.prompt, this.currApp.flags?.custom_devices);
    },
    filteredRetentions() {
      return (this.allPromptStore.prompts || [])
        .filter(this.hasSameDeviceTypeAsCurrPrompt)
        .filter(this.promoDisplayTypeVal);
    },
    sequence() {
      if (!this.prompt.sequence_id || !this.prompt.sequence_name) return null;
      return {
        sequence_id: this.prompt.sequence_id,
        sequence_name: this.prompt.sequence_name,
      };
    },
    isDisabledByActiveExperiment() {
      return isLatestExperimentActive({ path: this.prompt }) && "Disabled due to active experiment";
    },
    isDisabledByDraftExperiment() {
      return (
        isLatestExperimentUnstarted({ path: this.prompt }) && "Disabled due to existing experiment"
      );
    },
    isLocalized() {
      return isLocalized(this.prompt);
    },
    hasCustomGoal() {
      return isCustomGoalExists(this.prompt);
    },
    isInvisible() {
      return isInvisible(this.prompt);
    },
    totalCustomGoals() {
      if (this.isInvisible)
        return (
          (this.hasCustomGoal
            ? this.metrics?.custom_goals_accepted?.[this.date?.preset?.value]?.uniques
            : this.metrics?.goals?.[this.date?.preset?.value]?.uniques) || 0
        );
      return getTotals(this.metrics, "custom_goals_seen", this.date?.preset?.value);
    },
    isSurvey() {
      return isSurvey(this.prompt);
    },
    customDevicesEnabled() {
      return this.currApp?.flags?.custom_devices && this.prompt?.device_type === "custom_defined";
    },
    displayMetrics() {
      return getDisplayMetrics(this.prompt);
    },
    displayMetricsCustom() {
      return getDisplayCustomMetrics(this.prompt, this.displayMetrics);
    },
    displayLiveFeed() {
      return ![PromptTypes.email.value, PromptTypes.scheduled_push.value].includes(
        this.prompt?.path_type,
      );
    },
  },
  created() {
    this.promptStore.$reset();
    const preset = MetricsUtils.All_Periods.find(
      ({ value }) => value === this.getUserSetting("promotionsDateRange"),
    )?.value;

    if (preset) this.defaultDate = preset;
  },
  async mounted() {
    this.$nextTick(async () => {
      this.skeleton = true;
      await this.init();
      this.skeleton = false;
    });
    if (!this.clientActions.length) this.actionsStore?.getClientActions(this.currApp.id);
  },
  methods: {
    async fetchMetrics() {
      if (this.metrics[this.date.preset.value] || this.dataCard?.selectedTab?.key === "activity")
        return;

      this.dataSkeleton = true;
      const attrs = {
        appId: this.currApp.id,
        id: this.$route.params.pid,
        params: {
          metric_periods: [
            {
              period: this.date.preset.value,
              ...(this.date.preset.value === "custom" && {
                date_range: [
                  this.date.startDate.format("YYYY-MM-DD"),
                  this.date.endDate.format("YYYY-MM-DD"),
                ],
              }),
            },
          ],
        },
      };

      await Promise.all([
        this.promptStore.getPathMetrics(attrs),
        this.isSurvey
          ? this.promptStore.getPathCustomMetrics({
              ...attrs,
              params: { ...attrs.params, metric_keys: [PATH_CUSTOM_METRIC_SURVEY] },
            })
          : () => null,
      ]);

      this.dataSkeleton = false;

      if (this.date.preset.value !== "custom")
        this.setUserSetting("promotionsDateRange", this.date.preset.value);
    },
    async init() {
      const { pid: pathId, aid: appId } = this.$route.params;
      return Promise.all([
        this.promptStore.getPrompt({ appId, pathId }),
        this.experimentsStore.getExperiments(pathId),
        this.appsStore.getAppConnectors(appId),
      ]);
    },
    hasSameDeviceTypeAsCurrPrompt(item) {
      return item.device_type === this.prompt?.device_type;
    },
    hasSamePathTypeAsCurrPrompt(item) {
      return item.path_type === this.prompt?.path_type;
    },
    isPromptOverlay() {
      return isOverlay(this.prompt);
    },
    promoDisplayTypeVal(item) {
      return this.isPromptOverlay ? isOverlay(item) : this.hasSamePathTypeAsCurrPrompt(item);
    },
    async submitStatusUpdate([prompt]) {
      await this.updatePrompt({ ...this.prompt, ...prompt }, "Status updated");
      this.$refs.modals.closeStatusModal();
    },
    submitDelete() {
      const body = `Prompt${this.deleteItem.length > 1 ? "s" : ""} deleted`;
      this.promptsStore.bulkDelete(this.currApp.id, this.deleteItem).then(() => {
        this.$refs.modals.closeDeleteModal();
        this.toastsStore.create({ type: "success", body });
        this.goBack();
      });
    },
    async submitClone({
      targetAppId,
      targetAppName,
      pathGroupId,
      sequenceId,
      sequenceName,
      defaultCompany,
    }) {
      let body = `Cloned ${this.cloneItem.name}`;
      let timeout;
      try {
        const path = await this.promptStore.clonePath({
          appId: this.currApp.id,
          pathId: this.cloneItem.id,
          targetAppId,
          pathGroupId,
          sequenceId,
          defaultCompany,
        });
        this.$refs.modals.closeCloneModal();
        if (this.currApp.id === targetAppId) {
          if (path?.id) {
            const link = sequenceId
              ? getGuidePromptLink(path.id, sequenceId, this.currApp.id)
              : getPromptLink(path.id, this.currApp.id);
            this.$router.push(link);
            this.init();
          }
          if (sequenceId && sequenceName) {
            body += ` to "${sequenceName}"`;
            timeout = 5000;
          }
        } else {
          body += ` to ${targetAppName}`;
          timeout = 5000;
        }

        this.toastsStore.create({ type: "success", body, timeout });
      } catch (error) {
        this.toastsStore.create({ type: "error", body: error.message });
      }
    },
    goBack() {
      if (this.$route.name === "Retention Detail")
        this.$router.push(getPromptsLink(null, this.currApp.id));
      if (this.$route.name === "Pipeline's Retention Detail")
        this.$router.push(getPipelineLink(this.$route.params.plid, this.currApp.id));
      if (this.$route.name === "Guide's Retention Detail")
        this.$router.push(getGuideLink(this.$route.params.sid, this.currApp.id));
      if (this.$route.name === "Placements's Retention Detail")
        this.$router.push(getZoneLink(this.$route.params.plid, this.currApp.id));
    },
    submitReset() {
      this.promptStore
        .resetPathGoals({
          appId: this.prompt.app_id,
          pathId: this.prompt.id,
        })
        .then(this.init)
        .then(() => {
          this.toastsStore.create({ type: "success", body: "Clicks reset successfully" });
          this.resetModal?.close();
        });
    },
    async updatePrompt(newPath, message) {
      try {
        await this.promptStore.updatePrompt({
          appId: this.currApp.id,
          pathId: this.prompt.id,
          modelPath: newPath,
        });
        this.customGoalModal?.close();
        this.detailModals.closeSegmentsModal();
        this.detailModals.closeLimitsModal();
        this.detailModals.closeScheduleModal();
        this.detailModals.closeIntervalModal();
        this.connectorModal.close?.();
        this.toastsStore.create({
          type: "success",
          body: message || "Prompt updated successfully",
        });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    async updateTriggers({ newPath }) {
      try {
        await this.promptStore.updatePrompt({
          appId: this.currApp.id,
          pathId: newPath.id,
          modelPath: newPath,
        });

        this.detailModals.closeTriggersModal();
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
      } catch (e) {}
    },
    async updateCustomGoal() {
      const newRetention = cloneDeep(this.prompt);
      this.goal.fillModel(newRetention);
      this.updatePrompt(newRetention);
    },
    resetCustomGoal() {
      const newRetention = cloneDeep(this.prompt);
      this.updatePrompt({ ...newRetention, custom_filter: {} });
    },
    async saveExperiment() {
      try {
        const modelExperiment = { ...this.legacyTmpStore.experiment };
        modelExperiment.algorithm = "weighed_sample";
        if (!this.experimentName) throw new Error("Experiment name can't be empty");
        modelExperiment.name = this.experimentName;
        modelExperiment.variations = [
          {
            name: "Control",
            traffic_percent: 0,
            is_control: true,
            is_baseline: false,
            is_visible: false,
            actions: {},
            action_group_connector_actions: [],
            triggers: [],
          },
          {
            name: "Original",
            traffic_percent: 0,
            is_control: false,
            is_baseline: true,
            is_visible: true,
            actions: this.prompt.actions,
            action_group_connector_actions: this.prompt.action_group_connector_actions,
            triggers: this.prompt.triggers,
          },
        ];
        const experiment = await this.experimentsStore.createExperiment({
          pathId: this.prompt.id,
          modelExperiment,
        });
        this.newExperimentModal.close();
        if (experiment) {
          this.toastsStore.create({ type: "success", body: "Experiment created successfully" });
          this.$router.replace({
            path: getExperimentLink(experiment.id, this.prompt.id, this.currApp.id),
          });
        } else {
          this.toastsStore.create({ type: "error", body: this.lastError });
        }
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    updateExperiment({ experiment, running }) {
      if (!running) {
        if (experiment.variations.reduce((acc, el) => acc + el.traffic_percent, 0) !== 100)
          return this.toastsStore.create({
            type: "error",
            body: "Your traffic percentages must add up to 100%",
          });
      }

      this.experiemntToUpdate = experiment;
      this.$nextTick(() => this.statusExperimentModal.show());
    },
    async submitExperiment() {
      await this.experimentsStore.runExperiment({
        appId: this.currApp.id,
        pathId: this.prompt.id,
        expId: this.experiemntToUpdate.id,
        isStart: !this.experiemntToUpdate.is_active,
      });
      if (this.prompt.experiment?.id === this.experiemntToUpdate.id) {
        const prompt = {
          ...this.prompt,
          experiment: {
            ...this.prompt.experiment,
            is_active: !this.experiemntToUpdate.is_active,
          },
        };

        this.promptStore.getPrompt({
          appId: this.$route.params.aid,
          pathId: this.$route.params.pid,
        });
      }
      this.statusExperimentModal.close();
    },

    updateClientActions(action) {
      let index = -1;
      if (action.id) {
        index = this.prompt.action_group_connector_actions.findIndex(el => el.id === action.id);
      }
      const restActions = this.prompt.action_group_connector_actions.filter(el =>
        el.connectable_type === "ClientAction" && action.connectable_type === "ClientAction"
          ? el.interaction_type !== action.interaction_type ||
            action.args?.survey_option_selected !== el.args?.survey_option_selected
          : el.id !== action.id,
      );

      restActions.splice(index, 0, action);

      this.updatePrompt({
        ...this.prompt,
        action_group_connector_actions: restActions,
      })
        .then(() => this.connectorModal.close?.())
        .catch(_ => null);
    },
    updateActionsOrder(actions) {
      const prompt = {
        ...this.prompt,
        action_group_connector_actions: actions.concat(
          this.actionGroupConnectorActionsGroupedByType.ClientAction || [],
        ),
      };
      this.updatePrompt(prompt);
    },
    deleteAction(action) {
      const prompt = { ...this.prompt };
      if (isRedirectAction(action.actionName)) {
        prompt.actions[action.actionName] = null;
      } else {
        prompt.action_group_connector_actions = this.prompt.action_group_connector_actions.filter(
          el => el.id !== action.id,
        );
      }
      this.updatePrompt(prompt)
        .then(() => this.connectorModal.closeDelete?.())
        .catch(_ => null);
    },
    modifyPrompt(prompt, tag, add = false) {
      this.promptStore.mutateUpdatePrompt({
        prompt: {
          ...prompt,
          tags: [...(prompt?.tags || []).filter(({ id }) => tag.id !== id), ...(add ? [tag] : [])],
        },
        activities: this.promptStore.activities,
      });
    },
    addTagToPromptLocal(prompt, tag) {
      this.modifyPrompt(prompt, tag, true);
    },
    removeTagFromPromptLocal(prompt, tag) {
      this.modifyPrompt(prompt, tag);
    },
    async createTagAndAssignToPrompt(tagProp) {
      const tag = await this.tagsStore.createTag(this.currApp.id, tagProp);
      this.assignTagToPrompt(tag);
    },
    removeTagFromPrompt(tag) {
      this.removeTagFromPromptLocal(this.prompt, tag);
      ApiTags.removeTagFromPrompt(this.prompt.id, tag.id)
        .then(() => null)
        .catch(error => {
          this.addTagToPromptLocal(this.prompt, tag);
          this.toastsStore.create({ type: "error", body: error.message });
        });
    },
    async assignTagToPrompt(tag) {
      this.addTagToPromptLocal(this.prompt, tag);
      ApiTags.assignTagToPrompt(this.prompt.id, tag.id)
        .then(() => null)
        .catch(error => {
          this.removeTagFromPromptLocal(this.prompt, tag);
          this.toastsStore.create({ type: "error", body: error.message });
        });
    },
  },
  watch: {
    "prompt.id": function (v) {
      if (!v || this.$route.hash !== "#details") return;
      this.$router.push({ hash: "" });
      setTimeout(() => (location.href = `${this.$route.fullPath}#details`));
    },
  },
};
</script>

<style lang="scss">
:deep(.rf-prompt--live .rf-input--input-wrapper) {
  @apply bg-input-background #{!important};
}
</style>

import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiSequences {
  static getSequences(appId, params) {
    const query = serializeQuery(params);
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences?${query}`);
  }

  static createSequence(appId, sequence) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sequence),
    });
  }

  static getSequence(appId, sequenceId, params) {
    const query = serializeQuery(params);
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences/${sequenceId}?${query}`);
  }

  static deleteSequence(appId, sequenceId, sequence) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences/${sequenceId}`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sequence),
    });
  }

  static updateSequence(appId, sequenceId, sequence) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences/${sequenceId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sequence),
    });
  }

  static cloneSequence(appId, sequenceId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences/${sequenceId}/clone`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  static cloneSequenceToApp(appId, sequenceId, targetAppId, defaultCompany) {
    const endpoint = defaultCompany ? "clone_to_app" : "clone_to_company";
    const query = serializeQuery({ clone_app_id: targetAppId });
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/sequences/${sequenceId}/${endpoint}?${query}`,
      {
        method: "POST",
        headers: { "Accept": "application/json", "Content-Type": "application/json" },
      },
    );
  }

  static bulkUpdate(appId, guides) {
    if (!guides?.length) throw new Error("Expected second parameter to be a non empty array");

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences/bulk_update`, {
      method: "PUT",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({ sequences: guides }),
    });
  }

  static bulkDelete(appId, guides) {
    if (!guides?.length) throw new Error("Expected second parameter to be a non empty array");

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sequences/bulk_delete`, {
      method: "DELETE",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({ sequences: guides }),
    });
  }
}

<template>
  <div :class="disabledDiv(disabled)">
    <!-- Include / Exclude -->
    <v-select
      outlined
      dense
      :disabled="disabled"
      hide-details
      v-model="options.negative_match"
      :items="matchTypeItems"
      height="32px"
      placeholder="Match Type"
      background-color="#ffffff"
      v-if="horizontal"
    />
    <!-- Date Type -->
    <div
      v-if="horizontal && matchTypeSelected && model.display_type === 'date_range'"
      :class="disabledDiv(disabled)"
    >
      <v-icon>chevron_right</v-icon>
      <v-select
        v-model="options.date_type"
        outlined
        dense
        hide-details
        background-color="#ffffff"
        placeholder="Date Type"
        height="32px"
        :disabled="disabled"
        :items="dateTypeOptions"
      />
    </div>
    <!-- Matcher Type -->
    <div
      v-if="horizontal && matchTypeSelected && matcherTypeOptions"
      :class="disabledDiv(disabled)"
    >
      <v-icon>chevron_right</v-icon>
      <v-select
        v-model="options[model.data_type === 'date' ? 'range_type' : 'matcher_type']"
        outlined
        dense
        hide-details
        background-color="#ffffff"
        placeholder="Matcher Type"
        height="32px"
        :disabled="disabled"
        :valueComparator="(a, b) => a === b || (!a && !b)"
        :items="matcherTypeOptions"
      />
    </div>
    <!-- Custom -->
    <v-icon
      v-if="
        horizontal && matchTypeSelected && (model.display_type !== 'date_range' || dateTypeSelected)
      "
    >
      chevron_right
    </v-icon>
    <!-- Custom: values_in -->
    <div v-if="matcherType === 'values_in' || rangeType === 'values_in'" class="flex">
      <v-text-field
        v-model.trim="inputValueSet"
        outlined
        dense
        hide-details
        placeholder="Values list"
        hint="Hello world"
        :disabled="disabled"
        background-color="#ffffff"
      />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon class="ml-1 mr-2" color="#888" size="16" v-on="on" v-bind="attrs">info</v-icon>
        </template>
        <div class="values-in-tooltip">
          <strong>"Values in" Format</strong>
          <span>e.g. <code>NULL,5,6,9...12,...3,20...</code></span>
          <span class="mt-2"> Matches if any condition is true: </span>
          <span> <code>NULL</code> &ndash; value is not set </span>
          <span> <code>5,6</code> &ndash; value is 5 or 6 </span>
          <span> <code>9...12</code> &ndash; value is between 9 and 12 (inclusive) </span>
          <span> <code>...3</code> &ndash; value is 3 or less </span>
          <span> <code>20...</code> &ndash; value is 20 or greater </span>
        </div>
      </v-tooltip>
    </div>
    <!-- Custom: slider -->
    <div
      v-else-if="model.display_type === 'slider' && matchTypeSelected"
      :class="disabledDiv(disabled)"
    >
      <RfCustomControlsSlider v-model="valueSet" :model="model" :disabled="disabled" />
    </div>
    <!-- Custom: range_select -->
    <div
      v-else-if="model.display_type === 'range_select' && matchTypeSelected"
      :class="disabledDiv(disabled)"
    >
      <RfCustomRangeSelector
        v-if="!matcherType"
        v-model="valueSet"
        :model="model"
        :disabled="disabled"
      />
    </div>
    <!-- Custom: multi_select -->
    <div
      v-else-if="model.display_type === 'multi_select' && matchTypeSelected"
      :class="disabledDiv(disabled)"
    >
      <v-autocomplete
        outlined
        dense
        hide-details
        chips
        multiple
        v-model="valueSet"
        :disabled="disabled"
        :items="model.data_type === 'boolean' ? booleanValues : model.values"
        :placeholder="model.name"
        background-color="#ffffff"
        style="min-width: 150px"
        :value-comparator="vSelectMatch"
        :menu-props="{ maxHeight: 410, offsetY: true, minWidth: 200 }"
      >
        <template #item="{ item, attrs }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs: tAttrs }">
              <div class="d-flex align-center flex-grow-1" v-on="on" v-bind="{ ...tAttrs }">
                <v-list-item-action>
                  <v-checkbox hide-details class="ml-n2 mt-0 pt-0" v-model="attrs.inputValue" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item?.text || item }}</v-list-item-title>
                </v-list-item-content>
              </div>
            </template>
            <span>{{ item?.text || item }}</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </div>
    <!-- Custom: text_input -->
    <div
      v-else-if="model.display_type === 'text_input' && matchTypeSelected"
      :class="disabledDiv(disabled)"
    >
      <v-text-field
        outlined
        dense
        hide-details
        :placeholder="model.name"
        :hint="model.description"
        v-model.trim="inputValueSet"
        :disabled="disabled"
        background-color="#ffffff"
        height="32px"
      ></v-text-field>
    </div>
    <!-- Custom: date_range -->
    <div
      v-if="model.display_type === 'date_range' && dateTypeSelected && matchTypeSelected"
      :class="disabledDiv(disabled)"
    >
      <div v-if="isDayRange && rangeType !== 'values_in'" :style="dayPickerStyle">
        <v-text-field
          v-model="valueSet[0]"
          v-if="minValAvail"
          outlined
          :disabled="disabled"
          dense
          hide-details
          placeholder="Days"
          background-color="#ffffff"
          :style="dateStyles"
        />
        <span v-if="rangeType === 'range'" style="margin-right: 8px; padding-top: 8px">to: </span>
        <v-text-field
          v-model="valueSet[1]"
          v-if="maxValAvail"
          outlined
          :disabled="disabled"
          dense
          hide-details
          placeholder="Days"
          background-color="#ffffff"
          :style="dateStyles"
        />
      </div>
      <v-select
        v-if="isDayRange"
        v-model="options.relative_date_type"
        outlined
        dense
        hide-details
        background-color="#ffffff"
        height="32px"
        :items="relativeDateTypeOptions"
        :disabled="disabled"
        :style="dateStyles"
      />
      <div :style="datePickerStyle">
        <RfStartStopDatePicker
          v-if="!isDayRange"
          ref="dateRangePicker"
          :dateRange="dateRange"
          :disabled="disabled"
          :showClearIcon="true"
          :hideTime="true"
          @dateRangeUpdate="dateRangeUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RfCustomControlsSlider from "@/components/RfCustomControlsSlider.vue";
import RfCustomRangeSelector from "@/components/RfCustomRangeSelector.vue";
import RfStartStopDatePicker from "@/components/RfStartStopDatePicker.vue";
import { MatchTypeItems } from "@/utils/constants/SegmentsConstants";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfCustomControls",
  components: { RfCustomControlsSlider, RfCustomRangeSelector, RfStartStopDatePicker },
  props: ["model", "values", "disabled", "currentOptions", "horizontal"],
  setup: () => ({ matchTypeItems: Object.values(MatchTypeItems) }),
  data() {
    const defaultOptions = {
      negative_match: null,
      range_type: null,
      date_type: null,
      relative_date_type: null,
      matcher_type: null,
    };

    return {
      valueSet: cloneDeep(this.values) || [],
      booleanValues: [
        { text: "Null (no value)", value: "NULL" },
        { text: "True", value: "1" },
        { text: "False", value: "0" },
      ],
      dateTypeOptions: [
        { text: "Day Range", value: "day_range" },
        { text: "Date Range", value: "date_range" },
      ],
      relativeDateTypeOptions: [
        { text: "Days Ago", value: "days_ago" },
        { text: "Days From Now", value: "days_from_now" },
      ],
      options: cloneDeep({ ...defaultOptions, ...this.currentOptions }),
    };
  },
  computed: {
    matcherTypeOptions() {
      const { data_type, display_type } = this.model;
      if (data_type === "string" && display_type === "text_input")
        return [
          { text: "Exact Text", value: null },
          { text: "Wildcard", value: "wildcard" },
          { text: "Regular Expression", value: "regex" },
        ];
      if (data_type === "date" && this.isDayRange)
        return [
          { text: "From To", value: "range" },
          { text: "Less than or equal to", value: "less_than_or_equal_to" },
          { text: "Greater than or equal to", value: "greater_than_or_equal_to" },
          { text: "Values In (advanced)", value: "values_in" },
        ];
      if (data_type === "number" && ["text_input", "range_select"].includes(display_type))
        return [
          { text: display_type === "text_input" ? "Exact Number" : "From To", value: null },
          { text: "Values In (advanced)", value: "values_in" },
        ];

      return null;
    },
    inputValueSet: {
      get() {
        return (this.valueSet && this.valueSet[0]) || [];
      },
      set(value) {
        if (!value.length) {
          this.valueSet = [];
        } else {
          this.valueSet = [value];
        }
      },
    },
    dateRange() {
      return {
        startDate: this.valueSet && this.valueSet[0],
        endDate: this.valueSet && this.valueSet[1],
      };
    },
    isDayRange() {
      return this.dateType === "day_range";
    },
    minValAvail() {
      return this.isDayRange && ["range", "greater_than_or_equal_to"].includes(this.rangeType);
    },
    maxValAvail() {
      return this.isDayRange && ["range", "less_than_or_equal_to"].includes(this.rangeType);
    },
    rangeType() {
      return this.options.range_type;
    },
    matcherType() {
      return this.options.matcher_type;
    },
    dateType() {
      return this.options.date_type;
    },
    dateStyles() {
      return this.horizontal ? { "margin-right": "8px" } : { "margin-bottom": "8px" };
    },
    datePickerStyle() {
      if (this.horizontal) return {};
      return { "margin-top": "8px" };
    },
    dayPickerStyle() {
      const styles = { display: "inherit" };
      if (!this.horizontal) styles["margin-top"] = "8px";
      return styles;
    },
    matchTypeSelected() {
      return !this.horizontal || this.options.negative_match !== null;
    },
    dateTypeSelected() {
      return !this.horizontal || !!this.options.date_type;
    },
  },
  methods: {
    vSelectMatch(value1, value2) {
      try {
        const escaped = value1.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
        const regexValueCaseInsensitive = new RegExp(`^${escaped}\$`, "i");
        return regexValueCaseInsensitive.test(value2);
      } catch {
        return value1 === value2;
      }
    },
    disabledDiv(val) {
      return `flex ${val ? "disabled-custom-field" : ""}`;
    },
    dateRangeUpdate(data) {
      this.valueSet = [data.startDate || "", data.endDate || ""];
    },
  },
  watch: {
    valueSet: {
      handler(to) {
        const options = Object.keys(this.options).reduce((opts, key) => {
          if (this.options[key] !== null) opts[key] = this.options[key];
          return opts;
        }, {});
        this.$emit("update", to, options);
      },
      deep: true,
    },
    options: {
      handler(to) {
        this.$emit("update", this.valueSet, to);
      },
      deep: true,
    },
    dateType(to) {
      if (to === "day_range") {
        this.options.range_type = "range";
        this.options.relative_date_type = "days_ago";
      } else {
        this.options.range_type = null;
        this.options.relative_date_type = null;
      }
      this.valueSet = [];
    },
    rangeType(to) {
      if (to === "less_than_or_equal_to") {
        this.valueSet[0] = 0;
        this.valueSet[1] = null;
      } else if (to === "greater_than_or_equal_to") {
        this.valueSet[1] = "INF";
        this.valueSet[0] = null;
      }
    },
    matcherType(to) {
      if (to === "values_in") this.valueSet = [this.valueSet[0]];
    },
  },
};
</script>

<style scoped lang="scss">
.values-in-tooltip {
  display: flex;
  flex-direction: column;
  code {
    background: #000;
  }
}

:deep(.schedule-date-selector-wrapper) {
  > .container > .row {
    flex-direction: row;
  }
  .date-selector {
    flex: 1;
    display: flex;
    &:first-child {
      margin-right: 16px;
    }
    &:last-child:before {
      content: "to: ";
      margin: 6px 8px 0 -8px;
    }
  }
  .date-selector-text {
    display: none;
  }
}
</style>

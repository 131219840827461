<template>
  <div class="pd-segments-comp">
    <RfPromoDetailsInfoRow title="Segments">
      <template v-slot:promodetail>
        <div v-if="path.sequence_id">
          Inherited from <router-link :to="sequenceLink">{{ path.sequence_name }}</router-link>
        </div>
        <div
          :key="SegmentsComptKey"
          v-else-if="segments.length > 0 && !path.sequence_id"
          style="margin-left: -5px"
        >
          <router-link
            v-for="segment in [...segments].sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))"
            :key="segment.name"
            :to="segmentLink(segment.id)"
            class="pd-segments-comp--segments-link"
          >
            {{ segment.name }}
            <RfSegmentBucketRange
              class="segment-bucket-range"
              :showWarning="segments.length > 1"
              :bucketRange="bucketRange(segment)"
            />
          </router-link>
        </div>
        <div class="empty-segments-info" v-else>N/A</div>
        <div class="pd-info-edit pd-info-edit--segment" v-if="!path.sequence_id">
          <RfButton
            icon-size="16"
            title="edit"
            icon="edit"
            color="accent"
            x-small
            :disabled="editDisabled"
            @click="showPromoDetailDialog"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showPromotionInfoDialog"
      width="720"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Segments</v-card-title>
        <v-card-text style="padding-bottom: 40px">
          <RfLinkedSegments
            :model="path"
            ref="linkedSegments"
            :key="path.id"
            :pipelineStageId="pipelineStageId"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed outlined class="cancel-btn" width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn
            large
            @click="submitConfiguration"
            width="200px"
            color="primary"
            depressed
            :disabled="editDisabled"
          >
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfLinkedSegments from "@/components/RfPathCreate/RfLinkedSegments.vue";
import RfSegmentBucketRange from "@/components/RfSegments/RfSegmentBucketRange.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { useSequencesStore } from "@/pinia/sequencesStore";
import { usePromptStore } from "@/pinia/promptStore";
import { segmentItemFor, displayBucketRange } from "@/utils/segmentsHelpers";

export default {
  name: "RfPromoSegmentsInfo",
  props: ["path", "editDisabled", "pathGroupDisplay", "pipelineStageId"],
  setup: () => ({
    toastsStore: useToastsStore(),
    sequencesStore: useSequencesStore(),
    promptStore: usePromptStore(),
  }),
  components: { RfPromoDetailsInfoRow, RfLinkedSegments, RfSegmentBucketRange, RfButton },
  data: () => ({ showPromotionInfoDialog: false, SegmentsComptKey: 0 }),
  computed: {
    sequenceLink() {
      return { path: `/apps/${this.$route.params.aid}/experiences/${this.path.sequence_id}` };
    },
    segments() {
      return this.path.segments;
    },
  },
  methods: {
    segmentLink(segmentId) {
      return { path: `/apps/${this.$route.params.aid}/segments/${segmentId}` };
    },
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    showPromoDetailDialog() {
      this.showPromotionInfoDialog = true;
    },
    async submitConfiguration() {
      try {
        const appId = this.$route.params.aid;
        const newPath = cloneDeep(this.path);
        this.$refs.linkedSegments.fillModel(newPath);
        let title = "Prompt updated successfully";
        this.showPromotionInfoDialog = false;
        if (this.path.subject_name === "Sequence") {
          await this.sequencesStore.updateSequence({
            appId,
            sequenceId: this.path.id,
            modelSequence: newPath,
          });
          title = "Guide updated successfully";
        } else if (this.pathGroupDisplay) {
          await this.promptStore.updatePathGroup({ appId, model: newPath });
        } else {
          await this.promptStore.updatePrompt({
            appId,
            pathId: this.$route.params.pid,
            modelPath: newPath,
          });
        }
        this.toastsStore.create({ type: "success", body: title });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    bucketRange(segment) {
      return displayBucketRange(segmentItemFor(segment, this.path));
    },
  },
  watch: {
    path() {
      this.SegmentsComptKey++;
    },
  },
};
</script>

<style lang="scss" scoped>
.segment-bucket-range {
  &:before {
    content: " ( ";
  }
  &:after {
    content: " ) ";
  }
}
</style>

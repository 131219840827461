<template>
  <RfBaseModal
    ref="cloneGuideModal"
    title="Clone Guide"
    width="580"
    secondary-text="Clone"
    :disabledPrimary="(!cloneAppTarget.value && 'App is required')"
    @secondary="handleClone"
    @close="emit('update:item', null)"
  >
    <template #body>
      <div class="flex flex-col gap-4">
        <div>
          This will create a copy of your guide and its paths with the current
          design and details. This will <span style="font-style: italic;">not</span>
          clone the Custom Goals, Triggers, Actions, and Experiments.
        </div>
        <div v-if="showAppPicker">
          You can also clone the basic design and details into another app.
        </div>
        <RfSelect
          v-if="isSuperAdmin"
          v-model="cloneCompanyTarget"
          :items="companies"
          dense
          outlined
          hide-details
          return-object
          title="Company"
          :rules="[value => !!value || 'Company is required.']"
        />
        <template v-if="showAppPicker">
          <RfSelect
            v-model="cloneAppTarget"
            :items="apps"
            dense
            outlined
            hide-details
            return-object
            title="App"
            :rules="[value => !!value || 'App is required.']"
          />
          <v-alert v-if="!cloneIntoCurrApp" dense outlined type="warning" border="left">
            Custom Goals, Actions, Triggers, Experiments, Segments, and certain other prompt
            properties will not be cloned.
          </v-alert>
        </template>
      </div>
    </template>
  </RfBaseModal>
</template>

<script setup>
import ApiApps from "@/apis/ApiApps";
import ApiCompanies from "@/apis/ApiCompanies";
import ApiPathGroups from "@/apis/ApiPathGroups";
import ApiSequences from "@/apis/ApiSequences";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { catchable } from "@/pinia/piniaUtils";
import { isRunning, isInvalidSequenceTypeForPromptDevice } from "@/utils/guideHelpers";
import { computed, onMounted, ref, watch } from "vue";

const emit = defineEmits(["clone"]);
const props = defineProps({
  sequence: { type: Object, default: null },
  isSuperAdmin: { type: Boolean, default: false },
  isCompanyAdmin: { type: Boolean, default: false },
  currApp: { type: Object, default: () => ({}) },
  currUser: { type: Object, default: () => ({}) },
});

const apps = ref([]);
const companies = ref([]);
const cloneGuideModal = ref();
const cloneAppTarget = ref({});
const cloneCompanyTarget = ref({});

const cloneIntoCurrApp = computed(() => props.currApp.id === cloneAppTarget.value.value);
const showAppPicker = computed(
  () =>
    apps.value.length >
    (!cloneCompanyTarget.value.value || cloneCompanyTarget.value.value === props.currUser.company_id
      ? 1
      : 0),
);

const getApps = companyId =>
  catchable({
    t: async () => {
      const appsData = await ApiApps.getApps(companyId);
      apps.value = (
        props.isSuperAdmin || props.isCompanyAdmin
          ? appsData
          : appsData.filter(app => {
              const userApp = props.currUser.apps.find(userApp => userApp.id === app.id);
              return userApp && userApp.role !== "read_only";
            })
      ).map(el => ({ text: el.name, value: el.id }));
      if (showAppPicker.value)
        cloneAppTarget.value =
          apps.value.find(el => el.value === props.currApp?.id) || apps.value[0];
      else if (
        (!props.isSuperAdmin && !props.isCompanyAdmin) ||
        (apps.value.length === 1 && apps.value[0].value === props.currApp.id)
      )
        cloneAppTarget.value = { value: props.currApp.id, text: props.currApp.name };
      else cloneAppTarget.value = {};
    },
    loadable: true,
  });
const show = async () => {
  cloneGuideModal.value?.show();
  await Promise.all([
    ...(props.isSuperAdmin
      ? [
          catchable({
            t: async () =>
              (companies.value = (await ApiCompanies.getCompanies()).map(el => ({
                text: el.name,
                value: el.id,
              }))),
            loadable: true,
          }),
        ]
      : []),
    getApps(props.currUser.company_id),
  ]);
  if (props.isSuperAdmin)
    cloneCompanyTarget.value = companies.value.find(el => el.value === props.currUser.company_id);
};

const close = () => cloneGuideModal.value?.close();

const handleClone = () => {
  emit("clone", {
    targetAppId: cloneAppTarget.value?.value,
    targetAppName: cloneAppTarget.value?.text,
    sequenceId: props.sequence?.id,
    defaultCompany: !cloneCompanyTarget.value || props.currUser.company_id === cloneCompanyTarget.value,
  });

  close();
};

watch(() => cloneCompanyTarget.value?.value, getApps);

defineExpose({ show, close });
</script>

<template>
  <span>
    <span
      class="rf-button-input relative"
      :class="{ 'rf-button-input--has-value': props.value }"
      data-cy="search"
    >
      <Transition :duration="200" name="button-input" mode="out-in">
        <span v-if="closed" key="button">
          <button
            class="rf-button-input--button text-body flex items-center justify-center"
            @click="open"
          >
            <component
              v-if="props.icon"
              :is="props.icon"
              class="rf-button-input--button-icon !h-6 !w-6"
            />
            {{ text }}
          </button>
        </span>

        <RfInput
          v-else
          key="input"
          :value="props.value"
          ref="inputEl"
          :size="props.size"
          :placeholder="props.placeholder"
          @input="v => emit('input', v)"
          @blur="close"
        >
          <template #append>
            <div
              class="rf-button-input--search-icon inline-flex pr-2 [&>*]:m-auto [&>*]:!h-6 [&>*]:!w-6"
            >
              <component v-if="props.icon" :is="props.icon" />
            </div>
          </template>
          <template #prepend>
            <button class="text-body inline-flex !h-6 !w-6" @click="clear">
              <RfCloseIcon class="m-auto !h-6 !w-6" />
            </button>
          </template>
        </RfInput>
      </Transition>
    </span>
  </span>
</template>

<script setup>
import { nextTick, ref, watchEffect } from "vue";
import RfCloseIcon from "@/components/icons/RfCloseIcon.vue";
import RfInput from "./RfInput.vue";

const props = defineProps({
  text: { type: String, default: "Default" },
  icon: { type: undefined, default: null },
  value: { type: String, default: () => "" },
  placeholder: { type: String, default: "Input" },
  size: { type: String, default: "base", validator: v => ["base"].includes(v) },
});

const emit = defineEmits(["input"]);

const closed = ref(true);
const inputEl = ref();

const open = focus => {
  closed.value = false;

  // if (focus) setTimeout(() => inputEl.value?.$el.querySelector("input").focus(), 250);
};

const close = () => {
  if (!props.value) {
    closed.value = true;
  }
};

const clear = () => {
  emit("input", null);
  nextTick(close);
};

watchEffect(() => props.value && open(false));

defineExpose({ closed });
</script>

<style lang="scss" scoped>
.button-input-enter-active {
  transition: all 0.2s ease-out;
}

.button-input-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.button-input-enter-from,
.button-input-leave-to {
  opacity: 0;
}

.rf-button-input {
  &--button {
    gap: 9px;
    padding: 8px 12px 8px 17px;
  }
}
.rf-button-input--has-value {
  :deep(.rf-input--input-wrapper.rf-input--base) {
    @apply outline outline-1 outline-blue-1;
  }
  :deep(.rf-input .rf-button-input--search-icon svg) {
    @apply fill-blue-1;
  }
}
</style>

<template>
  <router-link class="all-sequences-row-outer" :to="getGuideLink(sequence.id, $route.params.aid)">
    <v-card>
      <div class="all-sequences-row" height="auto">
        <div class="all-sequences-thumb">
          <RfPromotionThumbnail :item="sequence.paths[0]" v-if="sequence.paths.length" />
          <div class="all-sequences-thumb-counter">
            {{ sequence.paths.length }}
          </div>
        </div>
        <div class="all-sequences-info">
          <div class="all-sequences-name d-flex pr-4">
            <h2 class="flex-1">
              {{ truncate(sequence.name, 30) }}
            </h2>
            <div class="flex-0 d-flex h-8 items-center gap-2">
              <RfSegmentBucketRange
                class="inline-flex items-center gap-1 text-blue-1"
                :showWarning="sequence.segments.length > 1"
                :bucketRange="bucketRange"
              />
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    small
                    @click.stop.prevent="() => openAssignTrafficModal(sequence)"
                  >
                    <v-icon size="16"> fas fa-random </v-icon>
                  </v-btn>
                </template>
                <span>Assign traffic</span>
              </v-tooltip>
            </div>
          </div>
          <div :class="gridView ? 'all-sequences-details-grid' : 'all-sequences-details'">
            <div>
              <div class="field-name">Show Prompts</div>
              <div class="field">{{ displaySequenceType }}</div>
            </div>
            <div>
              <div class="field-name">Segments</div>
              <div class="field">
                <div v-for="(segment, index) in sequence.segments" :key="index">
                  <RouterLink
                    class="field-segment"
                    :to="getSegmentLink(segment.id, $route.params.aid)"
                  >
                    {{ segment.name }}
                  </RouterLink>
                </div>
                <div v-if="sequence.segments === undefined || sequence.segments.length === 0">
                  None
                </div>
              </div>
            </div>
            <div>
              <div class="field-name">Schedule</div>
              <div class="field">
                <span> Starts on {{ dateDisplay(sequence.start_date) }} </span>
                <span> Ends on {{ dateDisplay(sequence.end_date) }} </span>
              </div>
            </div>
            <div>
              <div class="field-name">Status</div>
              <div class="field"><RfPromoPublishStatus :item="sequence" /></div>
            </div>
          </div>
        </div>
        <div class="all-sequences-metrics">
          <RfPlacementsStats
            :model="sequence.paths"
            :metricsKey="currChartSrc"
            :autoHeight="true"
            :class="
              gridView
                ? 'rf-metrics-arrow-responsive rf-metrics-grid'
                : 'rf-metrics-arrow-responsive'
            "
          />
        </div>
      </div>
    </v-card>
  </router-link>
</template>

<script>
import RfPromoPublishStatus from "@/components/RfPromotions/RfPromoPublishStatus.vue";
import RfPlacementsStats from "@/components/RfPlacements/RfPlacementsStats.vue";
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfSegmentBucketRange from "@/components/RfSegments/RfSegmentBucketRange.vue";
import StringUtils from "@/utils/StringUtils";
import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";
import { dateDisplay } from "@/utils/DateDisplayUtils";
import { getGuideLink, getSegmentLink } from "@/utils/getLink";

export default {
  name: "RfSequencesListView",
  components: {
    RfPromoPublishStatus,
    RfPlacementsStats,
    RfPromotionThumbnail,
    RfSegmentBucketRange,
  },
  props: ["gridView", "sequence", "currChartSrc", "openAssignTrafficModal", "bucketRange"],
  setup: () => ({ truncate: StringUtils.truncate, dateDisplay, getSegmentLink, getGuideLink }),
  computed: {
    displaySequenceType() {
      return GUIDE_TYPES[this.sequence.sequence_type]?.label || GUIDE_TYPES.survey.label;
    },
  },
};
</script>

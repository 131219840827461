import jwtDecode from "jwt-decode";
import { flatten } from "lodash-es";

const redirectToLogin = () => {
  const location = {
    pathname: window.location.pathname,
    hash: window.location.hash,
    search: window.location.search,
  };
  const redirectObject = encodeURIComponent(JSON.stringify(location));
  window.location = `/login?redirect=${redirectObject}`;
};

export const ApiBaseUrl = process.env.VUE_APP_ENDPOINT;
export const authenticatedFetch = (url, options) => {
  const idToken = localStorage.getItem("id_token");
  const hasIdToken = !!(idToken && idToken.length);
  const opts = options || {};
  if (!opts.headers) {
    opts.headers = {};
  }
  const impersonator = localStorage.getItem("impersonator");
  const isImpersonating = impersonator && impersonator.length && impersonator !== "null";
  if (isImpersonating) {
    opts.headers = { ...opts.headers, "Rf-Impersonator": impersonator };
  }

  // aws marketplace signup page = /aws_marketplace/{x-amzn-marketplace-token}
  // bypass authentication
  const awsMarketplaceSignupPage = /aws\_marketplace(?!$)/g;
  if (window.location.pathname.match(awsMarketplaceSignupPage)) return null;

  if (
    ["/invitation", "/password", "/register"].findIndex(i => i === window.location.pathname) < 0
  ) {
    if (hasIdToken) {
      try {
        const tokenExpiration = jwtDecode(localStorage.getItem("id_token")).exp;
        const isTokenExpired = tokenExpiration < new Date().getTime() / 1000;
        if (isTokenExpired) {
          if (window.location.pathname !== "/login") {
            localStorage.setItem("id_token", "");
            redirectToLogin();
          }
        }
      } catch (e) {
        if (window.location.pathname !== "/login") {
          localStorage.setItem("id_token", "");
          redirectToLogin();
        }
      }

      opts.headers.Authorization = `Bearer ${idToken}`;
    } else if (window.location.pathname !== "/login") {
      localStorage.setItem("id_token", "");
      redirectToLogin();
    }
  }
  return fetch(url, opts).then(res => {
    if (res.ok) {
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return res.json();
      }
      return Promise.resolve({ body: res.text() });
    }
    if (res.status === 401) {
      if (
        ["/invitation", "/password", "/register", "/login"].findIndex(
          i => i === window.location.pathname,
        ) < 0
      ) {
        localStorage.setItem("id_token", "");
        redirectToLogin();
      } else {
        return Promise.reject(new Error("You must login to continue"));
      }
    } else if (res.status === 500) {
      return Promise.reject(new Error("A problem occurred please refresh and try again"));
    } else if (res.status === 403) {
      return Promise.reject(new Error("Access denied"));
    } else if (res.status === 404) {
      return Promise.reject(new Error("Page could not be found", { cause: 404 }));
    } else {
      return res.text().then(text => {
        try {
          const error = JSON.parse(text);
          const key = Object.keys(error)[0];

          text = flatten([error[key]]).join(", ");
        } catch (e) {
          return Promise.reject(new Error("A problem occurred please refresh and try again"));
        }

        return Promise.reject(new Error(text));
      });
    }

    return null;
  });
};

import { render, staticRenderFns } from "./RfPromptDetails.vue?vue&type=template&id=025d26d0&scoped=true"
import script from "./RfPromptDetails.vue?vue&type=script&setup=true&lang=js"
export * from "./RfPromptDetails.vue?vue&type=script&setup=true&lang=js"
import style0 from "./RfPromptDetails.vue?vue&type=style&index=0&id=025d26d0&prod&scoped=true&lang=css"
import style1 from "./RfPromptDetails.vue?vue&type=style&index=1&id=025d26d0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "025d26d0",
  null
  
)

export default component.exports
<template>
  <div class="rf-settings-integration">
    <div style="padding: 5px 0 15px 10px">
      Select the connectors in use for this application. Refer to the
      <a target="_blank" href="https://help.redfast.com/docs/connector-actions">documentation</a> on
      how to obtain the required information.
    </div>
    <div class="connectors-grid-ls">
      <div
        class="connector-item-ct"
        v-for="item in getProviderList"
        :data-rf-provider="item.key"
        :key="item.key"
      >
        <RfButton
          class="connector-item-inner"
          block
          :disabled="userStore.isDisabledWithMessage"
          @click="showConnectorDialog(item)"
        >
          <img :src="item.src" :width="item.width" />
          <div class="connector-error-msg">
            <v-icon
              size="14"
              :color="'error'"
              v-if="
                app.providers[item.key] &&
                app.providers[item.key].active &&
                app.providers[item.key].status === 'error'
              "
              >lens</v-icon
            >
          </div>
        </RfButton>
      </div>
    </div>
    <v-dialog v-model="connectorDialog" width="700" content-class="show-connector-error-dialog">
      <v-card flat>
        <v-card-title class="connector-modal-headline">
          {{ dialogValue?.name || dialogValue.key.split("_").join(" ") }} Details
        </v-card-title>
        <v-card-text>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'sendgrid'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.sendgrid.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.sendgrid.api_key"
              />
            </div>
            <RfConnectorActionsTable :actions="sendgridActions" />
          </div>
          <div
            v-if="dialogValue.key === 'salesforce'"
            class="connector-edit-wrapper salesforce-connector"
          >
            <v-switch
              :disabled="userStore.isDisabledWithMessage"
              inset
              v-model="app.providers.salesforce.active"
              label="Active"
              hide-details
            ></v-switch>
            <div class="connectors-col">
              <div class="settings-salesforce-col1">
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="USERNAME"
                  v-model="app.providers.salesforce.username"
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="PASSWORD"
                  v-model="app.providers.salesforce.password"
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="SECURITY TOKEN"
                  v-model="app.providers.salesforce.security_token"
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="CLIENT ID"
                  v-model="app.providers.salesforce.client_id"
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="CLIENT SECRET"
                  v-model="app.providers.salesforce.client_secret"
                />
              </div>
              <div class="settings-salesforce-col2">
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="MARKETING CLOUD CLIENT ID"
                  v-model="app.providers.salesforce.marketing_cloud_client_id"
                  placeholder=" "
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="MARKETING CLOUD CLIENT SECRET"
                  v-model="app.providers.salesforce.marketing_cloud_client_secret"
                  placeholder=" "
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="MARKETING CLOUD BASE URL"
                  v-model="app.providers.salesforce.base_url"
                  placeholder=" "
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="MARKETING CLOUD AUTH BASE URL"
                  v-model="app.providers.salesforce.auth_base_url"
                  placeholder=" "
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="MARKETING CLOUD SOAP BASE URL"
                  v-model="app.providers.salesforce.soap_base_url"
                  placeholder=" "
                />
                <RfTextInput
                  :disabled="userStore.isDisabledWithMessage"
                  outlined
                  dense
                  title="FROM EMAIL"
                  v-model="app.providers.salesforce.from_email"
                />
              </div>
            </div>
            <RfConnectorActionsTable :actions="salesforceActions" />
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'braze'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.braze.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="BASE URL"
                v-model="app.providers.braze.base_url"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                v-model="app.providers.braze.api_key"
              />
            </div>
            <RfConnectorActionsTable :actions="brazeActions" />
          </div>
          <div v-if="dialogValue.key === 'apple'">
            <div class="connector-edit-div">
              <RfTextInput
                outlined
                dense
                title="Apple Push Notifications Service Bundle ID"
                placeholder="Apple Push Notifications Service Bundle ID"
                v-model="app.providers.redfast.pinpoint_apns_bundle_id"
              />
              <RfTextInput
                outlined
                dense
                title="Apple Push Notifications Service Team ID"
                placeholder="Apple Push Notifications Service Team ID"
                v-model="app.providers.redfast.pinpoint_apns_team_id"
              />
              <RfTextInput
                outlined
                dense
                textarea
                title="Apple Push Notifications Service Token Key"
                placeholder="Apple Push Notifications Service Token Key"
                v-model="app.providers.redfast.pinpoint_apns_token_key"
              />
              <RfTextInput
                outlined
                dense
                title="Apple Push Notifications Service Token Key ID"
                placeholder="Apple Push Notifications Service Token Key ID"
                v-model="app.providers.redfast.pinpoint_apns_token_key_id"
              />
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.apple.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfAppleProductIds :app="app" :disabled="userStore.isDisabledWithMessage" />
            </div>
          </div>
          <div v-if="dialogValue.key === 'stripe'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.stripe.active"
                label="Active"
                hide-details
              ></v-switch>
              <a
                class="my-5 block"
                :href="stripeConnectUrl"
                v-if="!app.providers.stripe.account_id"
              >
                <img src="@/assets/images/stripe_connect.png" width="200px" />
              </a>
              <RfTextInput
                :disabled="true"
                :readonly="true"
                placeholder="Stripe Connect Account ID"
                outlined
                dense
                title="ACCOUNT ID"
                hint="Go to https://dashboard.stripe.com/account/applications to manage your account"
                persistent-hint
                v-model="app.providers.stripe.account_id"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="SECRET KEY"
                placeholder="SECRET KEY"
                v-model="app.providers.stripe.secret_key"
              />
            </div>
            <RfConnectorActionsTable :actions="stripeActions" />
          </div>
          <div v-if="dialogValue.key === 'vindicia'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.vindicia.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="URL"
                placehold="URL"
                v-model="app.providers.vindicia.base_url"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="USERNAME"
                placehold="USERNAME"
                v-model="app.providers.vindicia.username"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="PASSWORD"
                placehold="PASSWORD"
                v-model="app.providers.vindicia.password"
              />
            </div>
            <RfConnectorActionsTable :actions="vindiciaActions" />
          </div>
          <div v-if="dialogValue.key === 'braintree'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.braintree.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfSelect
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="ENVIRONMENT"
                placeholder="ENVIRONMENT"
                v-model="app.providers.braintree.environment"
                :items="['sandbox', 'production']"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="MERCHANT ID"
                placeholder="MERCHANT ID"
                v-model="app.providers.braintree.merchant_id"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="PUBLIC KEY"
                placeholder="PUBLIC KEY"
                v-model="app.providers.braintree.public_key"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="PRIVATE KEY"
                placeholder="PRIVATE KEY"
                v-model="app.providers.braintree.private_key"
              />
            </div>
            <RfConnectorActionsTable :actions="braintreeActions" />
          </div>
          <div v-if="dialogValue.key === 'zendesk'" class="connector-edit-wrapper">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.zendesk.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="DOMAIN"
                placeholder="DOMAIN"
                v-model="app.providers.zendesk.domain"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="USERNAME"
                placeholder="USERNAME"
                v-model="app.providers.zendesk.username"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.zendesk.api_key"
              />
            </div>
            <RfConnectorActionsTable :actions="zendeskActions" />
          </div>
          <div v-if="dialogValue.key === 'freshdesk'" class="connector-edit-wrapper">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.freshdesk.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="DOMAIN"
                placeholder="DOMAIN"
                v-model="app.providers.freshdesk.domain"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.freshdesk.api_key"
              />
            </div>
            <RfConnectorActionsTable :actions="freshdeskActions" />
          </div>
          <div v-if="dialogValue.key === 'chargify'" class="connector-edit-wrapper">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.chargify.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="DOMAIN"
                placeholder="DOMAIN"
                v-model="app.providers.chargify.subdomain"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.chargify.api_key"
              />
            </div>
            <RfConnectorActionsTable :actions="chargifyActions" />
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'shopify'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.shopify.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="SHOP URL"
                placeholder="SHOP URL"
                v-model="app.providers.shopify.shop_url"
              />
            </div>
            <RfConnectorActionsTable :actions="shopifyActions" />
          </div>
          <div v-if="dialogValue.key === 'zuora'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.zuora.active"
                title="Active"
                placeholder="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API URL"
                placeholder="API URL"
                v-model="app.providers.zuora.api_url"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="CLIENT ID"
                placeholder="CLIENT ID"
                v-model="app.providers.zuora.client_id"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="CLIENT SECRET"
                placeholder="CLIENT SECRET"
                v-model="app.providers.zuora.client_secret"
              />
            </div>
            <RfConnectorActionsTable :actions="zuoraActions" />
          </div>
          <div v-if="dialogValue.key === 'segment'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                inset
                v-model="app.providers.segment.active"
                label="Active"
                hide-details
              ></v-switch>
              <!-- Access Tokens section -->
              <h3 class="mb-4">Access Tokens</h3>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="Unify Access Token"
                placeholder="Unify Access Token"
                v-model="app.providers.segment.unify_access_token"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="Unify Space ID"
                placeholder="Unify Space ID"
                v-model="app.providers.segment.unify_space_id"
              />
              <!-- destination details -->
              <h3 class="mb-4">Destination Details</h3>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="Lambda AWS ARN"
                placeholder="Lambda AWS ARN"
                v-model="app.providers.segment.lambda_aws_arn"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="AWS Region"
                placeholder="AWS Region"
                v-model="app.providers.segment.aws_region"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="AWS Role"
                placeholder="AWS Role"
                v-model="app.providers.segment.lambda_aws_role"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="Client Context"
                placeholder="Client Context"
                v-model="app.providers.segment.lambda_aws_client_context"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="External Id"
                placeholder="External Id"
                v-model="app.providers.segment.lambda_aws_external_id"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                :readonly="isReadonlyRoleMixin"
                outlined
                dense
                title="Log Type"
                placeholder="Log Type"
                v-model="app.providers.segment.lambda_aws_log_type"
              />
            </div>
          </div>
          <div v-if="dialogValue.key === 'mixpanel'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.mixpanel.active"
                label="Active"
                hide-details
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Service Account Username"
                placeholder="Service Account Username"
                v-model="app.providers.mixpanel.username"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Service Account Password"
                placeholder="Service Account Password"
                v-model="app.providers.mixpanel.password"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Project Token"
                placeholder="Project Token"
                v-model="app.providers.mixpanel.api_token"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Project ID"
                placeholder="Project ID"
                v-model="app.providers.mixpanel.project_id"
              />
              <RfConnectorActionsTable :actions="mixpanelActions" />
            </div>
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'recurly'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.recurly.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.recurly.api_key"
              />
              <v-checkbox
                :disabled="userStore.isDisabledWithMessage"
                v-model="app.providers.recurly.lookup_by_account_code"
                label="Use Account Code"
                hide-details
              ></v-checkbox>
            </div>
            <RfConnectorActionsTable :actions="recurlyActions" />
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'google_maps'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.flags.google_maps_active"
                label="Active"
                hide-details
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                hide-details
                v-model="app.providers.google_play.maps_api_key"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="PACKAGE NAME"
                placeholder="PACKAGE NAME"
                hide-details
                v-model="app.providers.google_play.package_name"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="CLIENT ID"
                placeholder="CLIENT ID"
                hide-details
                v-model="app.providers.google_play.ingest_client_id"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="CLIENT EMAIL"
                placeholder="CLIENT EMAIL"
                hide-details
                v-model="app.providers.google_play.ingest_client_email"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="PRIVATE KEY"
                placeholder="PRIVATE KEY"
                hide-details
                v-model="app.providers.google_play.ingest_private_key"
              />
            </div>
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'active_campaign'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.active_campaign.active"
                label="Active"
                hide-details
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.active_campaign.api_key"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Subdomain"
                placeholder="Subdomain"
                hide-details
                v-model="app.providers.active_campaign.subdomain"
              />
            </div>
          </div>
          <div v-if="dialogValue.key === 'iterable'" class="connector-edit-wrapper">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.iterable.active"
                label="Active"
                hide-details
              ></v-switch>
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.iterable.api_key"
              />
            </div>
            <RfConnectorActionsTable :actions="iterableActions" />
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'mparticle'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.mparticle.active"
                label="Active"
                hide-details
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.mparticle.api_key"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API SECRET"
                placeholder="API SECRET"
                v-model="app.providers.mparticle.api_secret"
              />
            </div>
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'cleeng'">
            <div class="connector-edit-div">
              <v-switch
                :disabled="userStore.isDisabledWithMessage"
                inset
                v-model="app.providers.cleeng.active"
                label="Active"
                hide-details
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="API KEY"
                placeholder="API KEY"
                v-model="app.providers.cleeng.api_key"
              />
              <v-checkbox
                :disabled="userStore.isDisabledWithMessage"
                v-model="app.providers.cleeng.sync_events"
                label="Sync Billing Events"
                hide-details
              ></v-checkbox>
              <RfConnectorActionsTable :actions="cleengActions" />
            </div>
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'amazon_adm'">
            <div class="connector-edit-div">
              <RfSelect
                outlined
                dense
                :disabled="userStore.isDisabledWithMessage"
                title="Region"
                placeholder="Region"
                v-model="app.providers.redfast.pinpoint_region"
                :items="awsRegions"
              /><RfTextInput
                outlined
                dense
                title="Role External Id (optional)"
                placeholder="Role External Id (optional)"
                v-model="app.providers.redfast.pinpoint_external_id"
              />
              <RfTextInput
                outlined
                dense
                title="Role ARN"
                placeholder="Role ARN"
                v-model="app.providers.redfast.pinpoint_external_arn"
              />
              <RfTextInput
                outlined
                dense
                title="Event Stream ARN"
                placeholder="Event Stream ARN"
                v-model="app.providers.redfast.pinpoint_external_event_stream_arn"
              />
              <RfTextInput
                outlined
                dense
                title="Event Stream Role ARN"
                placeholder="Event Stream Role ARN"
                v-model="app.providers.redfast.pinpoint_external_event_role_arn"
              />
              <RfTextInput
                outlined
                dense
                title="Amazon Device Messaging Client ID"
                placeholder="Amazon Device Messaging Client ID"
                v-model="app.providers.redfast.pinpoint_adm_client_id"
              />
              <RfTextInput
                outlined
                dense
                title="Amazon Device Messaging Client Secret"
                placeholder="Amazon Device Messaging Client Secret"
                v-model="app.providers.redfast.pinpoint_adm_client_secret"
              />
            </div>
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'google_fcm'">
            <div class="connector-edit-div">
              <RfTextInput
                outlined
                dense
                textarea
                title="Firebase Cloud Management Service JSON"
                placeholder="Firebase Cloud Management Service JSON"
                v-model="app.providers.redfast.pinpoint_fcm_service_json"
              />
            </div>
          </div>
          <div class="connector-edit-wrapper" v-if="dialogValue.key === 'adobe'">
            <div class="connector-edit-div">
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Identity Map Name"
                placeholder="rf_vid"
                v-model="app.providers.adobe.identity_map_name"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Event Type"
                placeholder="web.webpagedetails.pageViews"
                v-model="app.providers.adobe.event_type"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Instance Name"
                placeholder="redfasttechnologypartner"
                v-model="app.providers.adobe.instance_name"
              />
              <RfTextInput
                :disabled="userStore.isDisabledWithMessage"
                outlined
                dense
                title="Data Stream ID"
                placeholder="dbe419b3-8acc-4bcd-b8c0-c9b8670c0d1e"
                v-model="app.providers.adobe.data_stream_id"
              />
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large @click="closeConnectorDialog()" depressed class="cancel-btn px-7" outlined
            >Close</v-btn
          >
          <v-btn large depressed color="success" @click="connectorUpdateClick" class="px-10">
            <v-icon left>save</v-icon>Save changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfConnectorActionsTable from "@/components/RfSettings/RfConnectorActionsTable.vue";
import RfAppleProductIds from "@/components/RfSettings/RfAppleProductIds.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { useActionsStore } from "@/pinia/actionsStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfConnectors",
  props: ["app"],
  setup: () => ({ actionsStore: useActionsStore(), userStore: useUserStore() }),
  components: {
    RfConnectorActionsTable,
    RfAppleProductIds,
    RfTextInput,
    RfSelect,
    RfButton,
  },
  data() {
    const providerList = [
      {
        key: "sendgrid",
        type: "img",
        src: require("@/assets/images/logos/sendgrid.png"),
        width: "90",
      },
      {
        key: "salesforce",
        type: "img",
        src: require("@/assets/images/logos/salesforce.png"),
        width: "90",
      },
      {
        key: "braze",
        type: "img",
        src: require("@/assets/images/logos/logo-braze.svg"),
        width: "65",
      },
      {
        key: "apple",
        type: "img",
        src: require("@/assets/images/logos/logo-apple.png"),
        width: "90",
      },
      {
        key: "stripe",
        type: "img",
        src: require("@/assets/images/logos/stripe.png"),
        width: "60",
      },
      {
        key: "vindicia",
        type: "img",
        src: require("@/assets/images/logos/vindicia.png"),
        width: "82",
      },
      {
        key: "braintree",
        type: "img",
        src: require("@/assets/images/logos/braintree.gif"),
        width: "90",
      },
      {
        key: "zendesk",
        type: "img",
        src: require("@/assets/images/logos/zendesk.png"),
        width: "80",
      },
      {
        key: "freshdesk",
        type: "img",
        src: require("@/assets/images/logos/freshdesk.png"),
        width: "90",
      },
      {
        key: "shopify",
        type: "img",
        src: require("@/assets/images/logos/shopify.png"),
        width: "88",
      },
      {
        key: "zuora",
        type: "img",
        src: require("@/assets/images/logos/zuora.png"),
        width: "80",
      },
      {
        key: "segment",
        type: "img",
        src: require("@/assets/images/logos/segment_io.png"),
        width: "88",
      },
      {
        key: "chargify",
        type: "img",
        src: require("@/assets/images/logos/chargify.png"),
        width: "98",
      },
      {
        key: "mixpanel",
        type: "img",
        src: require("@/assets/images/logos/mixpanel.png"),
        width: "98",
      },
      {
        key: "recurly",
        type: "img",
        src: require("@/assets/images/logos/recurly.png"),
        width: "120",
      },
      {
        key: "google_maps",
        type: "img",
        src: require("@/assets/images/logos/google-maps-platform.png"),
        width: "150",
      },
      {
        key: "active_campaign",
        type: "img",
        src: require("@/assets/images/logos/activecampaign_logo.png"),
        width: "150",
      },
      {
        key: "iterable",
        type: "img",
        src: require("@/assets/images/logos/iterable_logo.png"),
        width: "150",
      },
      {
        key: "cleeng",
        type: "img",
        src: require("@/assets/images/logos/cleeng.svg"),
        width: "150",
      },
      {
        key: "amazon_adm",
        name: "Amazon",
        type: "img",
        src: require("@/assets/images/logos/logo-amazon.png"),
        width: "150",
      },
      {
        key: "google_fcm",
        name: "Google",
        type: "img",
        src: require("@/assets/images/logos/logo-google.png"),
        width: "150",
      },
    ];

    return {
      connectorDialog: false,
      dialogValue: providerList[0],
      currentBillingProvider: null,
      currentSupportProvider: null,
      currentMarketingProvider: null,
      mixpanelActions: [
        "Send events to Mixpanel",
        "Send user traits to Mixpanel",
        "Receive segments from Mixpanel",
        "Receive user traits from Mixpanel",
        "Receive events from Mixpanel",
      ],
      tab: null,
      providerList,
      awsRegions: [
        "us-east-1",
        "us-west-2",
        "ap-south-1",
        "ap-norhteast-1",
        "ap-northeast-2",
        "ap-southeast-1",
        "ap-southeast-2",
        "ca-central-1",
        "eu-central-1",
        "eu-west-1",
        "eu-west-2",
      ],
    };
  },
  computed: {
    connectorActions() {
      return this.actionsStore.connectorActions || [];
    },
    getProviderList() {
      const providerCopy = [...this.providerList];
      if (this.app.flags.mparticle_event_export)
        providerCopy.push({
          key: "mparticle",
          type: "img",
          src: require("@/assets/images/logos/logo-mparticle.svg"),
          width: "120",
        });
      if (this.app.flags.adobe_prompt_interaction_export)
        providerCopy.push({
          key: "adobe",
          type: "img",
          src: require("@/assets/images/logos/adobe.svg"),
          width: "120",
        });

      const redfit2 = "abdeb5f1-3773-4517-981a-2f146c88fabd";
      const redfit3 = "6304903d-6a41-4ebd-b4e9-16d520b5c835";
      const vindiciaApps = [redfit2, redfit3];
      if (vindiciaApps.indexOf(this.app.id) > -1) {
        const excludedIntegrations = ["braintree", "stripe"];
        return providerCopy.filter(item => excludedIntegrations.indexOf(item.key) < 0);
      }
      return [...providerCopy].sort((a, b) => `${a.key}`.localeCompare(`${b.key}`));
    },
    stripeConnectUrl() {
      const phaser = process.env.VUE_APP_ENDPOINT;
      return new URL(`${phaser}apps/${this.app.id}/stripe/connect`).href;
    },
    stripeActions() {
      return this.actionDescriptions("stripe");
    },
    zendeskActions() {
      return this.actionDescriptions("zendesk");
    },
    sendgridActions() {
      return this.actionDescriptions("sendgrid");
    },
    salesforceActions() {
      return this.actionDescriptions("salesforce");
    },
    brazeActions() {
      return this.actionDescriptions("braze");
    },
    vindiciaActions() {
      return this.actionDescriptions("vindicia");
    },
    braintreeActions() {
      return this.actionDescriptions("braintree");
    },
    shopifyActions() {
      return this.actionDescriptions("shopify");
    },
    zuoraActions() {
      return this.actionDescriptions("zuora");
    },
    freshdeskActions() {
      return this.actionDescriptions("freshdesk");
    },
    chargifyActions() {
      return this.actionDescriptions("chargify");
    },
    recurlyActions() {
      return this.actionDescriptions("recurly");
    },
    iterableActions() {
      return this.actionDescriptions("iterable");
    },
    cleengActions() {
      return this.actionDescriptions("cleeng");
    },
  },
  methods: {
    connectorUpdateClick() {
      this.connectorDialog = false;
      this.$emit("onSettingsUpdateClick");
    },
    showConnectorDialog(item) {
      this.connectorDialog = true;
      this.dialogValue = item;
    },
    closeConnectorDialog() {
      this.connectorDialog = false;
    },
    actionDescriptions(provider) {
      const providerActions = this.connectorActionsByProvider(provider);
      return providerActions.map(action => action.args.description);
    },
    connectorActionsByProvider(provider) {
      return this.connectorActions.filter(action => action.provider === provider);
    },
    loadProvider() {
      const connector = this.$route.params.pathMatch.replace("connectors/", "");

      if (connector) {
        const provider = this.providerList.find(item => item.key === connector);

        if (provider) this.showConnectorDialog(provider);
      }
    },
  },
  mounted() {
    this.loadProvider();
    if (!this.connectorActions.length) this.actionsStore.getConnectorActions(this.app.id);
  },
};
</script>
<style lang="scss">
.connector-modal-headline {
  text-transform: capitalize;
}
.connectors-grid-ls {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 25%;
    text-align: center;
    padding: 10px;
    height: 150px;
  }
}
.connector-item-ct {
  padding: 10px;
}
.connector-item-inner {
  background: #f9f9f9;
  border-radius: 5px;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}
.connector-error-msg {
  position: absolute;
  top: 3px;
  right: 8px;
}
.coming-soon-txt {
  background-color: #ec1f92;
  color: #fff;
  line-height: normal;
  padding: 3px 6px;
  position: absolute;
  left: 3px;
  top: 3px;
}
</style>
<style lang="scss" scoped>
.rf-settings-integration {
  padding: 1rem 30px 2rem;
}
</style>

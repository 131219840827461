<template>
  <div class="mobileFrameDisplay" :class="mobileDeviceType">
    <div class="mobileFrameInner">
      <RfCloseButtonPreview v-if="isInterstitial && closeButtonEnabled" :actions="actions" />
      <div :class="modalWrapperClasses" :style="modalBackgroundColor">
        <div class="mobilewebModalContentWrapper" ref="rfmodalbackground" :style="cssVars">
          <RfCloseButtonPreview v-if="closeButtonEnabled && !isInterstitial" :actions="actions" />
          <div class="rfmodalvideo-backgroundimage relative" v-if="isVideo">
            <div class="video-volume-control" v-if="isVideo">
              <span class="video-volume-up">
                <v-icon
                  size="24"
                  :style="{ color: fillColor }"
                  v-if="actions.rf_settings_mobile_video_muted"
                  >volume_off</v-icon
                >
                <v-icon size="24" class="video-volume-up" :style="{ color: fillColor }" v-else
                  >volume_up</v-icon
                >
              </span>
            </div>
            <video
              crossorigin="anonymous"
              ref="promoVideoPlayer"
              :autoplay="actions.rf_settings_mobile_video_autoplayed"
              :loop="actions.rf_settings_mobile_video_loop"
              :muted="actions.rf_settings_mobile_video_muted"
              :key="backgroundVideoKey"
              @ended="endedPlaying"
              :poster="actions.rf_settings_mobile_video_poster"
              style="max-width: 100%"
            >
              <source
                :src="backgroundVideo"
                :type="backgroundVideoType"
                v-if="videoMediaType !== 'm3u8'"
              />
              Your browser does not support the HTML5 Video element.
            </video>
            <v-icon
              v-if="!actions.rf_settings_mobile_video_autoplayed"
              size="24"
              class="rfmodal-play"
              :style="{ color: fillColor }"
            >
              fa-play
            </v-icon>
            <img
              ref="promoVideoPoster"
              class="videoPosterImgDefault"
              :src="actions.rf_settings_bg_image"
            />
          </div>
          <div>
            <div>
              <!-- adding two layered divs to match brig ^^ -->
              <div class="rfmodal-header-mobileweb">
                <h3 :style="mobileWebTitleFontSize" v-html="actions.rf_mobile_title"></h3>
              </div>
              <div class="rfmodal-body-mobileweb">
                <RfBodyCountdown :actions="actions" />
                <div
                  :style="mobileWebBodyFontSize"
                  v-html="mobileMessage"
                  class="rfmodal-message-mobileweb"
                ></div>
              </div>
            </div>
          </div>
          <RfPromoInputsPreview :actions="actions" />
          <RfPromoSurveyPreview :actions="actions" :isMobile="true" />
          <RfPromoConsentPreview
            :actions="actions"
            v-if="consent.needsConsent.value"
            v-model="consent.consentChecked.value"
          />
          <div>
            <!-- extra div to match brig ^^ -->
            <RfRetentionMessageButton
              :actions="actions"
              :acceptButtonEnabled="consent.consentNotNeededOrGiven.value"
              mobileWeb
            />
          </div>
          <RfRetentionLinksPreview :actions="actions" />
        </div>

        <div
          :style="containerMobileWebBackgroundImage"
          class="rfmodal-backgroundimage"
          ref="rfmodal"
          v-if="!isVideo"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import RfRetentionLinksPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionLinksPreview.vue";
import RfRetentionMessageButton from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessageButton.vue";
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import RfPromoInputsPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoInputsPreview.vue";
import RfCloseButtonPreview from "@/components/RfPathCreate/RfRetentionActions/RfCloseButtonPreview.vue";
import RfPromoSurveyPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoSurveyPreview.vue";
import RfPromoConsentPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoConsentPreview.vue";
import Hls from "hls.js";
import { bottomLeft, bottomRight, topLeft, topRight } from "@/utils/constants/PromptStyleConstants";
import { baseUrlReplacer } from "@/utils/BaseUrlReplacer";
import { useAppsStore } from "@/pinia/appsStore";
import { useConsentPreview } from "@/utils/composables/useConsentPreview";
import RfBodyCountdown from "./RfBodyCountdown.vue";

export default {
  name: "RfRetentionModalMobileContent",
  props: ["actions", "model"],
  setup: props => ({ appsStore: useAppsStore(), consent: useConsentPreview(props) }),
  components: {
    RfRetentionLinksPreview,
    RfRetentionMessageButton,
    RfPromoInputsPreview,
    RfCloseButtonPreview,
    RfPromoSurveyPreview,
    RfPromoConsentPreview,
    RfBodyCountdown,
  },
  mixins: [PromoPreviewMixin],
  computed: {
    isInterstitial() {
      return this.model.path_type === "interstitial";
    },
    backgroundVideoKey() {
      if (
        (this.actions.rf_settings_mobile_video_media_type &&
        this.actions.rf_settings_mobile_video_media_type.length === 0 ||
        this.actions.rf_settings_mobile_video_media_type === "m3u8") ||
        (this.actions.rf_settings_video_media_type.length === 0 ||
        this.actions.rf_settings_video_media_type === "m3u8"
        )
      ) {
        return "staticKey";
      }
      return this.backgroundVideo;
    },
    videoSrc() {
      return this.actions?.rf_settings_mobile_video_src || this.actions.rf_settings_video_src;
    },
    videoMediaType() {
      return this.actions?.rf_settings_mobile_video_media_type || this.actions.rf_settings_video_media_type;
    },
    isVideo() {
      return this.model.path_type === "video";
    },
    cssVars() {
      let bgColor;
      if (this.actions.button1_bg_color) {
        bgColor = this.actions.button1_bg_color;
      }
      if (this.isVideo) {
        return { "--btn-color": bgColor, "--txt-color": this.fillColor };
      }
      return {
        "padding-top": this.actions.rf_settings_mobile_title_padding_top,
        "--btn-color": bgColor,
        "--txt-color": this.fillColor,
      };
    },
    modalBackgroundColor() {
      return {
        "background-color": this.actions.rf_settings_bg_image_color,
        ...Object.fromEntries(
          [topRight, topLeft, bottomRight, bottomLeft].map(({ value }) => [
            `border-${value}-radius`,
            this.actions[`rf_mobile_border-${value}-radius`],
          ]),
        ),
        "overflow": "hidden",
        "border-width": this.actions["rf_mobile_border-width"],
        "border-style": this.actions["rf_mobile_border-style"],
        "border-color": this.actions["rf_mobile_border-color"],
        "box-sizing": this.actions["rf_mobile_box-sizing"],
        "box-shadow": this.actions["rf_mobile_box-shadow"],
      };
    },
    mobileWebTitleFontSize() {
      return {
        "font-size": this.actions.rf_settings_mobile_title_font_size,
      };
    },
    mobileWebBodyFontSize() {
      return {
        "font-size": this.actions.rf_settings_mobile_message_font_size,
      };
    },
    containerMobileWebBackgroundImage() {
      if (this.actions.rf_settings_mobile_bg_image) {
        return {
          "background-image": `url(${baseUrlReplacer(this.appsStore.app, this.actions.rf_settings_mobile_bg_image)})`,
        };
      }
      if (this.actions.rf_settings_bg_image) {
        return {
          "background-image": `url(${baseUrlReplacer(this.appsStore.app, this.actions.rf_settings_bg_image)})`,
        };
      }
      return {
        "background-image": "none",
      };
    },

    mobileDeviceType() {
      if (this.model.device_type === "android_os") {
        return "androidMobilePhone";
      }
      if (this.model.device_type === "ios") {
        return "iosMobilePhone appMocks";
      }
      if (this.model.device_type === "web") {
        return "iosMobilePhone";
      }
      return "";
    },
    backgroundVideo() {
      return this.actions.rf_settings_mobile_video_src || this.actions.rf_settings_video_src;
    },
    backgroundVideoType() {
      return `video/${this.actions.rf_settings_mobile_video_media_type || this.actions.rf_settings_video_media_type}`;
    },

    modalWrapperClasses() {
      const classes = ["mobileWebModalWrapper"];
      if (this.isVideo) classes.push("mobileWebVideo");
      return classes;
    },
  },
  methods: {
    endedPlaying() {
      this.$refs.promoVideoPlayer.classList.add("fadeOutVideo");
      this.$refs.promoVideoPoster.classList.add("fadeInPoster");
      this.showPoster = true;
    },
    attachHlsVideo(url) {
      this.$nextTick(() => {
        const mediaType = url.split(".").pop();
        if (mediaType === "m3u8") {
          const video = this.$refs.promoVideoPlayer;
          if (Hls.isSupported()) {
            this.hls.detachMedia();
            this.hls = new Hls();
            this.hls.attachMedia(video);
            this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
              this.hls.loadSource(url);
            });
          } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = url;
            video.addEventListener("loadedmetadata", () => {
              video.play();
            });
          }
        }
      });
    },
  },
  watch: {
    videoSrc(to, from) {
      if (to && to.length && to !== from) {
        this.attachHlsVideo(to);
      }
    }
  },
};
</script>

<style scoped>
.rfmodal-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.video-volume-control {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>

import { PromptTypes } from "./constants/PromoTypesConstants";
import { REDIRECTS_ACTION_OBJ } from "./constants/PromptActionsConstants";
import { isRedirectAction } from "./prompts/promptActionHelpers";

export default {
  computed: {
    interactionTypes() {
      const shouldShowOnlyPrimary =
        this.model.path_type === PromptTypes.text.value ||
        ([
          PromptTypes.horizontal.value,
          PromptTypes.vertical.value,
          PromptTypes.tile.value,
        ].includes(this.model.path_type) &&
          ["container", "none"].includes(this.actions?.rf_settings_tile_interaction));

      const types = [];
      if (this.model.path_type !== PromptTypes.invisible.value) {
        types.push({ value: "impression", text: "seen" });
      }
      types.push({
        value: "accept",
        text: shouldShowOnlyPrimary
          ? "primary"
          : this.getDisplayMetricsText("rf_retention_button1_text") ||
            (this.model.path_type === PromptTypes.invisible.value ? "accept" : "button 1"),
      });

      if (this.model.path_type === PromptTypes.invisible.value) return types.concat("holdout");
      if (!this.actions) return types;

      if (
        this.actions.rf_settings_close_button_enabled?.toLowerCase?.() === "true" ||
        this.actions.rf_settings_click_outside_close_enabled?.toLowerCase?.() === "true"
      ) {
        types.push("dismiss");
      }

      if (this.actions.rf_settings_close_seconds) {
        types.push("timeout");
      }

      if (shouldShowOnlyPrimary) return types;

      if (this.actions?.rf_settings_confirm_button_2_enabled?.toLowerCase?.() === "true") {
        types.push({
          value: "accept2",
          text: this.getDisplayMetricsText("rf_retention_button2_text") || "button 2",
        });
      }

      if (this.actions.rf_settings_cancel_button_enabled?.toLowerCase?.() === "true") {
        types.push({
          value: "decline",
          text: this.getDisplayMetricsText("rf_retention_button3_text") || "button 3",
        });
      }

      return types;
    },
  },
  methods: {
    isRedirectAction(actionName) {
      return isRedirectAction(actionName);
    },
    getActiveActions() {
      let validInteractionTypes = {
        accept_redirect: true,
        accept2_redirect: true,
        decline_redirect: true,
        dismiss_redirect: true,
        timeout_redirect: true,
      };
      if (this.interactionTypes) {
        const standardInteractionTypes = this.interactionTypes.reduce((hash, item) => {
          const keyFromInteraction = typeof item === "object" ? item.value : item;
          hash[keyFromInteraction] = true;
          return hash;
        }, {});
        validInteractionTypes = {
          ...validInteractionTypes,
          ...standardInteractionTypes,
        };
      }
      const redirects = Object.entries(REDIRECTS_ACTION_OBJ).filter(([key, item]) => {
        const hasActionValue = this.actions[item] && this.actions[item].length > 0;
        const hasInteractionEnabled = validInteractionTypes[key];

        return hasActionValue && hasInteractionEnabled;
      });

      const websiteActions = (
        this.actionGroupClientActions ||
        this.action_group_connector_actions.filter(
          item =>
            item.connectable_type === "ClientAction" &&
            this.interactionTypes.find(
              type => type === item.interaction_type || type.value === item.interaction_type,
            ),
        )
      ).map(item => [item.interaction_type, item.interaction_type, item.args]);

      return redirects.concat(websiteActions);
    },
    getDisplayMetricsText(key) {
      if (!this.model?.filter?.is_translated) return this.actions?.[key];
      return Object.values(this.model?.parsed_translations || {}).find(
        el => el.default === "TRUE",
      )?.[key];
    },
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mweb-widget-container"},[_c('div',{staticClass:"mweb-phone-kit-bg relative",class:_vm.model.device_type === 'ios'
        ? 'mweb-phone-kit-bg--clear-shrinked'
        : 'mweb-phone-kit-bg--clear'},[_c('div',{style:({
        'border-top-left-radius': _vm.model.device_type === 'ios' ? '13% 6%' : '0%',
        'border-top-right-radius': _vm.model.device_type === 'ios' ? '13% 6%' : '0%',
        'border-bottom-left-radius': '5% 4%',
        'border-bottom-right-radius': '5% 4%',
      })},[_c('div',{staticClass:"mweb-canvas-bound mweb-canvas-bound--interstitial mix-blend-multiply"},[_c('span',{staticClass:"rfmodal-close inline-flex flex-row-reverse",style:({ top: _vm.model.device_type === 'ios' ? '55px' : undefined })},[(_vm.actions.rf_settings_close_button_enabled === 'true')?_c('v-icon',{staticClass:"my-auto",style:({ color: _vm.actions.rf_settings_fill_color }),attrs:{"id":"rfmodal-close-icon"}},[_vm._v(" close ")]):_vm._e(),_c('RfPromptTimeout',{staticClass:"my-auto mr-2",attrs:{"actions":_vm.actions,"newVariant":""}})],1),_c('div',{staticClass:"h-full w-full overflow-hidden"},[_c('div',{staticClass:"h-full w-full",style:(_vm.containerMobileWebBackgroundImage)},[_c('div',{staticClass:"flex h-7/10"},[_c('div',{style:({
                  'text-align': _vm.actions.rf_retention_align,
                  'padding': `0 ${_vm.actions.rf_settings_body_padding_side}`,
                })},[_c('div',{style:(_vm.titleStyle)},[_vm._v(_vm._s(_vm.actions.rf_retention_title))]),_c('div',{style:(_vm.messageStyle)},[_vm._v(_vm._s(_vm.actions.rf_retention_message))])])]),_c('div',{staticClass:"flex h-3/10 flex-col"},[_c('div',{key:_vm.actions.rf_settings_privacy_policy_text,ref:"buttonContainer",staticClass:"mweb-btn-container flex flex-shrink-0 flex-grow flex-col"},[(!_vm.isNewDeviceEditor || _vm.actions.rf_retention_button1_text)?_c('button',{staticClass:"mx-8 text-lg",style:({
                    height: `${_vm.buttonHeight}px`,
                    marginTop: `${_vm.buttonPadding}px`,
                    ..._vm.acceptButtonStyle,
                    ..._vm.buttonsStyle,
                  }),domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button1_text)}}):_vm._e(),(
                    _vm.isNewDeviceEditor
                      ? _vm.actions.rf_settings_confirm_button_2_enabled &&
                        _vm.actions.rf_retention_button2_text
                      : _vm.actions.rf_settings_confirm_button_2_enabled
                  )?_c('button',{staticClass:"mx-8 text-lg",style:({
                    height: `${_vm.buttonHeight}px`,
                    marginTop: `${_vm.buttonPadding}px`,
                    ..._vm.secondaryButtonStyle,
                    ..._vm.buttonsStyle,
                  }),domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button2_text)}}):_vm._e(),(
                    _vm.isNewDeviceEditor
                      ? _vm.actions.rf_settings_cancel_button_enabled &&
                        _vm.actions.rf_retention_button3_text
                      : _vm.actions.rf_settings_cancel_button_enabled
                  )?_c('button',{staticClass:"mx-8 text-lg",style:({
                    height: `${_vm.buttonHeight}px`,
                    marginTop: `${_vm.buttonPadding}px`,
                    ..._vm.cancelButtonStyle,
                    ..._vm.buttonsStyle,
                  }),domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button3_text)}}):_vm._e()]),(_vm.actions.rf_settings_privacy_policy_text)?_c('RfRetentionLinksPreview',{staticClass:"!relative !bottom-0 !left-0 mx-8 mb-2 line-clamp-1 text-center",attrs:{"actions":_vm.actions}}):_vm._e()],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }